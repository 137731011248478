<template>
    <b-card class="card-activity-origin-destiny">
        <div class="container-card-header">
            <span class="title">
                {{ titleCard }}
            </span>
            <div class="container-buttons">
                <b-button
                    class="btn-scheduling btn-clean"
                    @click="cleanRows"
                >
                    <span>
                        {{ $t('IntegrationPage.Clean') }}
                    </span>
                </b-button>
                <b-button
                    class="btn-scheduling btn-import"
                    @click="importSchemas"
                >
                    <span>
                        {{ $t('IntegrationPage.ImportSchemas') }}
                    </span>
                </b-button>
            </div>
        </div>

        <b-col cols="12" class="p-0">
            <b-col class="d-flex flex-column flex-sm-row col-12 container-rows p-0" v-for="(item, index) in rowList" :key="index + '-fields-map-row'">
                <b-form-group
                    class="col-12 col-sm-5"
                    :label="$t('IntegrationPage.Source')"
                >
                    <SingleSelect
                        :id="`input-origin-${cardID}-${index}`"
                        :placeholder="$t('Select')"
                        :state="rowList[index].stateOrigin"
                        :optionSelected="fieldsMapOrigin.find(item => item.value == rowList[index].origin_id)"
                        :options="[rowList[index].origin_id ? fieldsMapOrigin.find(item => item.value == rowList[index].origin_id) : null, ...fieldsMapOrigin.filter(item1 => !rowList.map(item2 => item2.origin_id).includes(item1.value))].filter(item => item != null)"
                        @input="(value) => {rowList[index].origin_id = value ? value.value : null; rowList[index].origin_name = value ? value.value ? fieldsMapOrigin.filter(item => item.value == rowList[index].origin_id)[0].text : null : null; rowList[index].origin_type = value ? value.valie ? fieldsMapOrigin.filter(item => item.value == rowList[index].origin_id)[0].type : null : null; checkFields();}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>

                <b-form-group
                    class="col-12 col-sm-5"
                    :label="$t('IntegrationPage.Destination')"
                >
                    <SingleSelect
                        :id="`input-destiny-${cardID}-${index}`"
                        :placeholder="$t('Select')"
                        :state="rowList[index].stateDestiny"
                        :optionSelected="fieldsMapDestiny.find(item => item.value == rowList[index].destiny_id)"
                        :options="[rowList[index].destiny_id ? fieldsMapDestiny.find(item => item.value == rowList[index].destiny_id) : null, ...fieldsMapDestiny.filter(item1 => !rowList.map(item2 => item2.destiny_id).includes(item1.value))].filter(item => item != null)"
                        @input="(value) => {rowList[index].destiny_id = value ? value.value : null; rowList[index].destiny_name = value ? value.value ? fieldsMapDestiny.filter(item => item.value == rowList[index].destiny_id)[0].text : null : null; rowList[index].destiny_type = value ? value.value ? fieldsMapDestiny.filter(item => item.value == rowList[index].destiny_id)[0].type : null : null; checkFields(); changeDestiny = !changeDestiny;}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>

                <b-col class="d-flex align-items-center container-buttons col-12 col-sm-2 p-sm-0">
                    <b-button
                        :class="['btn-activity btn-add', rowList[index].stateOrigin == false || rowList[index].stateDestiny ? 'btn-activity-invalid' : '' ]"
                        v-if="(rowList.length - 1) == index "
                        @click="newRow()"
                    >
                        <AddIcon></AddIcon>
                    </b-button>

                    <b-button
                        v-if="rowList.length > 1"
                        :class="['btn-activity btn-remove', rowList[index].stateOrigin == false || rowList[index].stateDestiny ? 'btn-activity-invalid' : '']"
                        @click="deleteRow(index)"
                    >
                        <DeleteIcon></DeleteIcon>
                    </b-button>

                </b-col>
            </b-col>
        </b-col>
    </b-card>
</template>

<script lang="js">

import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import DeleteIcon from '@/assets/images/pages/icon-delete.svg'
import AddIcon from '@/assets/images/pages/icon-add.svg'
import Ripple from 'vue-ripple-directive';
import { mapGetters, mapMutations } from "vuex";
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle,
    BButton
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BCard,
        BButton,
        DeleteIcon,
        AddIcon,
        SingleSelect
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        titleCard: {
            type: String,
            default: ''
        },
        cardID: {
            type: String,
            default: '',
        },
        checkFieldsMode: {
            type: Boolean,
            default: false
        },
        fieldsMapOrigin:{
            type:Array,
            default:null
        },
        fieldsMapDestiny:{
            type:Array,
            default:null
        },
        editingData: {
            type: Object,
            default: {}
        },
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagCleanFields',
            'flagEditActivity',
            'flagMappingFieldsIbox',
            'flagIntegrationAction'
        ]),
    },
    watch: {
        rowList(){
            this.$emit('atualizaReturnFields', this.rowList)
        },
        flagCleanFields(v) {
            if(v) this.cleanRows();
        },
        fieldsMapDestiny() {
            this.cleanRows();
            if(this.flagEditActivity && this.flagMappingFieldsIbox) this.setDataForEditing();
        },
        fieldsMapOrigin() {
            this.cleanRows();
            if(this.flagEditActivity && this.flagMappingFieldsIbox) this.setDataForEditing();
        },
        changeDestiny() {
            this.$emit('setFieldMappingSelected', this.rowList.map(item => {return {value:item.destiny_id, label: item.destiny_name, type: item.destiny_type}}))
        },
        flagActivityButton(v) {
            if(v && this.flagMappingFieldsIbox && this.flagIntegrationAction) this.saveData();
        },
    },
    mounted() {
        if (this.flagEditActivity && this.flagMappingFieldsIbox) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            rowList: [
                { origin_id: null, origin_name:null, destiny_name:null, destiny_id: null, stateOrigin:null, stateDestiny: null }
            ],
            changeDestiny: false,
            flagEditLocal: false,
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_MAPPING_FIELDS_VALIDATION'
        ]),
        validateFields() {
            this.rowList.forEach(el => {
                el.stateOrigin = el.origin_id != null ? null : false;
                el.stateDestiny = el.destiny_id != null ? null : false;
            });

            let validate = this.rowList.find(el => el.stateOrigin == false || el.stateDestiny == false) == undefined ? true : false;

            this.UPDATE_FLAG_MAPPING_FIELDS_VALIDATION(validate);
            return validate;
        },
        newRow(){
            this.rowList.push({ origin_id: null, origin_name:null, destiny_name:null, destiny_id: null, stateOrigin:null, stateDestiny: null});
            this.checkFieldsMode ?  this.checkFields() : '';
        },
        deleteRow(index){
            this.rowList.splice(index,1);
            this.checkFieldsMode ?  this.checkFields() : '';
            this.changeDestiny = !this.changeDestiny;
        },
        checkFields() {
            this.$emit('atualizaReturnFields', this.rowList)

            let validations = this.rowList.map(item => item.destiny_id != null && item.destiny_id != "" && item.origin_id != null && item.origin_id != "" ? true : false)
            let validate = !validations.includes(false);
            if(validate) {
                this.$emit('showIBox', 'others');
            } else if(!validate && this.flagMappingFieldsIbox) {
                this.$emit('showIBox', 'fields-mapping');
            }
        },
        cleanRows() {
            this.rowList = [{ origin_id: null, origin_name:null, destiny_name:null, destiny_id: null, stateOrigin:null, stateDestiny: null}];
        },
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let data = this.rowList.map(row => {
                    return {
                        source_id: row.origin_id,
                        source_name: row.origin_name,
                        source_type: this.fieldsMapOrigin.find(origin => origin.value == row.origin_id)?.type,
                        destination_id: row.destiny_id,
                        destination_name: row.destiny_name,
                        destination_type: this.fieldsMapDestiny.find(destiny => destiny.value == row.destiny_id)?.type
                    }
                });

                this.$emit('setValue', data);
            }
        },
        setDataForEditing() {
            this.rowList =
                this.editingData.activity?.fieldsMapping?.map(item => {
                    return {
                        origin_id: item.source_id,
                        origin_name: item.source_name,
                        destiny_id: item.destination_id,
                        destiny_name: item.destination_name,
                        destiny_type: item.destination_type,
                        stateOrigin: null,
                        stateDestiny: null
                    }
                });
            this.$emit('setFieldMappingSelected', this.rowList.map(item => {return {value:item.destiny_id, label: item.destiny_name, type: item.destiny_type}}))

            setTimeout(()=>{this.checkFields()}, 100)
        },
        importSchemas() {
            let originIndex = null;
            let destinyIndex = null;
            let flagClean = true;
            this.rowList = [];

            this.fieldsMapOrigin.forEach((item1, i) => {
                this.fieldsMapDestiny.forEach((item2, j) => {

                    if(item1.text == item2.text) {
                        originIndex = i;
                        destinyIndex = j;

                        if(originIndex >= 0 && destinyIndex >= 0) {
                            let row = {
                                origin_id: this.fieldsMapOrigin[originIndex].value,
                                origin_name: this.fieldsMapOrigin[originIndex].text,
                                destiny_name:this.fieldsMapDestiny[destinyIndex].text,
                                destiny_id: this.fieldsMapDestiny[destinyIndex].value,
                                stateOrigin:null,
                                stateDestiny: null,
                                origin_type: this.fieldsMapOrigin[originIndex].type,
                                destiny_type: this.fieldsMapDestiny[destinyIndex].type
                            };

                            this.rowList.push(row);
                            flagClean = false;

                            return;
                        }
                    }
                })
            })

            if(flagClean) this.cleanRows();

            this.checkFieldsMode ?  this.checkFields() : '';

            this.changeDestiny = !this.changeDestiny;
        }
    },
}
</script>

<style lang="scss">
    .card-activity-origin-destiny {
        overflow-x: visible;

        .container-rows {
            margin-right: -1rem;
            margin-left: -1rem;
            .btn-activity {
                width: 38px;
                height: 38px;
                margin-top: 7px;
                padding: 0 !important;
                margin-right: 8px;
                border: none !important;
                &:hover {
                    box-shadow: none;
                }
            }

            .btn-activity-invalid {
                margin-top: -11px;
            }

            .btn-add {
                background: #974900 !important;
            }

            .btn-remove {
                background: #D32F2F !important;
            }
        }

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
            .container-buttons {
                display: flex;
                .btn-scheduling {
                    display: flex;
                    padding: 0 !important;
                    border-radius: 5px;
                    border: 1px solid transparent !important;
                    margin-left: 16px;
                    span {
                        width: 100%;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #FFFFFF;
                        letter-spacing: 0.0125em;
                        padding: 4px 14px;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }
                    &:active {
                        background: #A45F1F !important;
                    }
                }
                .btn-clean {
                    background: #fff !important;
                    border: 1px solid #974900 !important;
                    span {
                        color: #974900;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #FFEDE2 !important;
                    }
                    &:active {
                        background: #FFDBC4 !important;
                    }
                }

                .btn-import {
                    background: #974900 !important;
                    span {
                        color: #fff;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }
                    &:active {
                        background: #A45F1F !important;
                    }

                }
            }
        }
    }

    @media (max-width:480px) {
        .card-activity-origin-destiny {
            .container-card-header {
                flex-direction: column;
                .title {
                    align-self: flex-start;
                }
                .container-buttons {
                    flex-direction: column;
                    width: 100%;
                    margin-top: 16px;
                    .btn-scheduling {
                        width: 100%;
                        margin-left: 0 !important;
                    }

                    .btn-clean {
                        margin-bottom: 8px;
                    }
                }
            }

            .container-rows {
                padding: 16px 2px !important;
                border: 1px solid #CFC4BE;
                border-radius: 6px;
                flex-direction: column;
                margin: 7px 0 16px 0 !important;
                .container-buttons {
                    flex-direction: column;
                    .btn-activity {
                        width: 100%;
                        margin: 4px 0;
                    }
                    .btn-add {
                        background: #974900 !important;

                    }

                    .btn-remove {
                        background: #D32F2F !important;
                    }
                }
            }
        }

        .btn-float {
            right: 16px !important;
            .icon-btn-float {
                padding: 11px !important;
                scale: 0.9;
            }
        }
    }

</style>
