<template>
    <b-card id="card-pipeline-session">
        <b-col class="p-0">
            <div class="container-card-header">
                <span class="title">
                    {{ $t('IntegrationPage.Properties') }}
                </span>
            </div>
            <b-row cols="12">
                <b-col class="col-12 col-sm-6 col-md-4">
                    <b-form-group
                        :label="$t('IntegrationPage.NameField')"
                        :invalid-feedback="flagNameEqual ? $t('NameAlreadyExists') : $t('RequiredField')"
                    >
                        <b-form-input
                            :placeholder="$t('TypeHere')"
                            :state="flagNameValid"
                            v-model="pipelineName"
                            id="input-name-pip"
                        />
                    </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-6 col-md-4">
                    <b-form-group
                        :label="$t('IntegrationPage.PerformedViaField')"
                        class=""
                    >
                        <SingleSelect
                            id="input-workcenter2"
                            :placeholder="$t('Select')"
                            :state="flagPerformedViaValid"
                            :optionSelected="performedViaSelected"
                            :options="executionTypesList"
                            @input="(value) => performedViaSelected = value"
                        >
                            {{ $t('RequiredField') }}
                        </SingleSelect>
                    </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-12 col-md-4">
                    <b-form-group
                        :label="$t('IntegrationPage.EventField')"
                    >
                        <SingleSelectGroup
                            id="input-workcenter24"
                            :placeholder="$t('Select')"
                            :optionSelected="selectedEvent"
                            :options="setEventOptions()"
                            :state="(flagValidEvent == null && flagExistingEvent == null) && null"
                            @input="(value) => selectedEvent = value"
                        >
                            {{ flagValidEvent == false ? $t('RequiredField') : flagExistingEvent == false ? $t('IntegrationPage.EventAlreadyRegistred') : '' }}
                        </SingleSelectGroup>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
  </template>

  <script lang="js">
  import SingleSelectGroup from '@/@core/components/multiselect/SingleSelectGroup.vue'
  import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
  import Ripple from 'vue-ripple-directive';
  import { mapGetters, mapMutations } from "vuex";
  import {
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle,
    BButton,
    BCol
  } from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        BButton,
        BCol,
        SingleSelect,
        SingleSelectGroup
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },

    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            flagNameValid: null,
            flagNameEqual: false,
            flagValidEvent: null,
            flagExistingEvent: null,
            flagPerformedViaValid: null,
            pipelineName: '',
            performedViaSelected: null,
            selectedEvent: null,
            oldPipelineName: '',
            language: null,
            enableSetDataForEdit: false,
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagFloatSaveButton',
                'hasContentEventList',
                'hasContentExecutionTypeList',
                'flagGetPipeline',
                'flagDisableEventAlreadyRegistred'
            ]
        ),
        ...mapGetters('app', [
            'flagShowOverlay'
        ])
    },
    props: {
        executionTypesList:{
            type:Array,
            default:null
        },
        eventList:{
            type:Array,
            default:null
        },
        pipeline: {
            type: Object,
            default: {}
        },
        pipelinesList: {
            type: Array,
            default: []
        },
        checkConfirmPerformed: {
            type: Boolean,
            default: false
        },
        checkConfirmEvent: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        flagFloatSaveButton(v) {
            if(v) this.saveData();
        },
        pipelineName(v) {
            this.disableActivity();
        },
        performedViaSelected(v) {
            this.disableActivity();
            this.shareSelectedExecutionType();
            if(v != 1) this.flagExistingEvent = null;
            this.UPDATE_SELECTED_PERFORMED_VIA(v?.value);
        },
        language(v) {
            if(v) this.changeLanguageEventOption();
        },
        flagGetPipeline(v) {
            if(v && !this.flagShowOverlay && this.executionTypesList && this.eventList) this.enableSetDataForEdit = true;
        },
        hasContentEventList(v) {
            if(v && !this.flagShowOverlay && this.executionTypesList && this.flagGetPipeline) this.enableSetDataForEdit = true;
        },
        hasContentExecutionTypeList(v) {
            if(v && !this.flagShowOverlay && this.eventList && this.flagGetPipeline) this.enableSetDataForEdit = true;
        },
        enableSetDataForEdit(v) {
            if(v) this.setDataPipeline();
        },
        flagDisableEventAlreadyRegistred(v) {
            if(v) {
                this.selectedEvent = null;
                this.flagExistingEvent = false;
                this.UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED(false);
            }
        },
        selectedEvent(v) {
            if(v != null) this.flagExistingEvent = null;
            this.UPDATE_SELECTED_EVENT(v?.value);
        },
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_PIPELINE_SESSION_VALIDATION',
            'UPDATE_FLAG_DISABLE_EVENT_ALREADY_REGISTRED',
            'UPDATE_SELECTED_PERFORMED_VIA',
            'UPDATE_SELECTED_EVENT',
            'UPDATE_CONFIRM_CHANGE'
        ]),
        setEventOptions() {
            this.language = localStorage.getItem('language');
            return this.eventList;
        },
        changeLanguageEventOption() {
            let value = null;

            if(this.selectedEvent) {
                this.setEventOptions().forEach(option => {
                    option.groupLib.forEach(groupItem =>  {
                        if(groupItem.value == this.selectedEvent.value) return value = groupItem;
                    })

                    if(value) return;
                });
                this.selectedEvent = value
            }
        },
        validateFields() {
            let nameEqual = false;

            if(this.$route.params.action) {
                this.pipelinesList.forEach(item => {if(!nameEqual) {nameEqual = item.label == this.pipelineName && item.value != this.$route.params.id}});
                this.flagNameEqual = nameEqual;
            } else {
                this.pipelinesList.forEach(item => {if(!nameEqual) {nameEqual = item.label == this.pipelineName}});
                this.flagNameEqual = nameEqual && this.pipelineName != this.oldPipelineName? true : false;
            }

            this.flagNameValid = this.pipelineName != '' && this.flagNameEqual == false ? null : false;
            this.flagPerformedViaValid = !this.performedViaSelected ? false : null;
            this.flagValidEvent = this.performedViaSelected?.value == 1 && this.selectedEvent == null ? false : null;

            // alerta nas abas
            let validate =
                this.flagNameValid === null
                    && this.flagPerformedViaValid === null
                    && this.flagValidEvent === null
                    && this.flagExistingEvent === null
                    ? true : false;

            this.UPDATE_FLAG_PIPELINE_SESSION_VALIDATION(validate);

            return validate;
        },
        disableActivity() {
            let validate = this.pipelineName == '' || this.performedViaSelected == null;
            this.$emit('disableActivity', validate);
        },
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let object = {
                    name: this.pipelineName,
                    execution_type_id: this.performedViaSelected?.value,
                    event_id: this.selectedEvent?.value
                }

                this.$emit('saveData', object);
            }
        },
        setDataPipeline() {
            this.oldPipelineName = this.pipeline.pipeline.name;
            this.pipelineName = this.pipeline.pipeline.name;
            this.performedViaSelected = this.executionTypesList.find(item => item.value == this.pipeline.pipeline.execution_type_id);

            let eventOption = null;

            this.setEventOptions().forEach(option => {
                option.groupLib.forEach(groupItem =>  {
                    if(groupItem.value == this.pipeline.pipeline.event_id) return eventOption = groupItem
                })
                if(eventOption) return;
            });

            this.selectedEvent = eventOption;
        },
        cleanFieldsPipeline(){
            this.$emit('clean-fields-pipeline');
        },
        setPerformedVia(value) {
            this.performedViaSelected = this.executionTypesList.find(item => item.value == value);

            this.$nextTick(() => {
                this.UPDATE_CONFIRM_CHANGE(false);
            });
        },
        setEvent(value) {
            let opationValue = null;

            this.setEventOptions().forEach(option => {
                option.groupLib.forEach(groupItem =>  {
                    if(groupItem.value == value) return opationValue = groupItem;
                })

                if(opationValue) return;
            });

            this.selectedEvent = opationValue;

            this.$nextTick(() => {
                this.UPDATE_CONFIRM_CHANGE(false);
            });
        },
        shareSelectedExecutionType() {
            this.$emit('shareSelectedExecutionType', this.performedViaSelected.value);
        }
    },
}
</script>

<style lang="scss">
    #card-pipeline-session {
        .form-group {
            legend, label {
                color: #4C4541 !important;
            }
        }

        input {
            &:focus {
                box-shadow: none !important;
                border-color: #974900 !important;
            }

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #CFC4BE;
            }

            &.is-invalid {
                border: 1px solid #D32F2F!important;

                &:focus {
                    border-color: #D32F2F !important;
                }
            }
        }

        .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
            border-color: #974900 !important;
        }

        .is-invalid-span {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }



        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .vs--open .vs__dropdown-toggle,
        .select2-container--default.select2-container--open .select2-selection--single
        {
            box-shadow: none !important;
            border-color: #974900 !important;
            input {
                border-color: transparent !important;
            }
        }

        .card-body {
            padding: 16px 16px 0px 16px;
        }
        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }

        }
        #input-name-pip::placeholder {
            color: #CFC4BE;
        }
    }

    @media (max-width:480px) {

        #card-pipeline-session {
            .container-card-header {
                flex-direction: column;
                .title {
                    align-self: flex-start;
                }
            }
        }

        .btn-float {
            right: 16px !important;
            .icon-btn-float {
                padding: 11px !important;
                scale: 0.9;
            }
        }
    }
</style>
