<template>
    <b-card id="card-activity-integration">
        <div class="container-card-header">
            <span class="title">
                {{ $t('IntegrationPage.Integration') }}
            </span>
        </div>

        <div cols="12">
            <b-row>
                <b-form-group
                    class="col-12 col-sm-6"
                    :label="$t('IntegrationPage.SourceConnection')"
                >
                    <SingleSelect
                        id="input-origin-connection"
                        :placeholder="$t('Select')"
                        :state="flagValidOriginConnection"
                        :optionSelected="selectedOriginConnection"
                        :options="connectionsListOrigin"
                        @input="(value) => {selectedOriginConnection = value; selectedOriginConnection != null && filterObjects(selectedOriginConnection.value, 'origin')}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>

                <b-form-group
                    class="col-12 col-sm-6"
                    :label="$t('IntegrationPage.SourceObject')"
                    :invalid-feedback="flagActivityEqual ? $t('NameAlreadyExists') : $t('RequiredField')"
                >
                    <SingleSelect
                        id="input-origin-object"
                        :placeholder="$t('Select')"
                        :state="flagValidOriginObject"
                        :disabled="selectedOriginConnection == null || selectedOriginConnection == ''"
                        :optionSelected="selectedOriginObject"
                        :options="objectsOriginList"
                        @input="(value) => {selectedOriginObject = value; selectedOriginObject != null && filterFields(selectedOriginObject.value, 'origin')}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>
            </b-row>

            <b-row>
                <b-form-group
                    class="col-12 col-sm-6"
                    :label="$t('IntegrationPage.DestinationConnection')"
                >
                    <SingleSelect
                        id="input-destiny-connection"
                        :placeholder="$t('Select')"
                        :state="flagValidDestinyConnection"
                        :optionSelected="selectedDestinyConnection"
                        :options="connectionsListDestiny"
                        @input="(value) => {selectedDestinyConnection = value; selectedDestinyConnection != null && filterObjects(selectedDestinyConnection.value, 'destiny')}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>

                <b-form-group
                    class="col-12 col-sm-6"
                    :label="$t('IntegrationPage.DestinationObject')"
                    :invalid-feedback="flagActivityEqual ? $t('RefuseStatus.NameAlreadyExists') : $t('RequiredField')"
                >
                    <SingleSelect
                        id="input-destiny-object"
                        :placeholder="$t('Select')"
                        :state="flagValidDestinyObject"
                        :disabled="selectedDestinyConnection == null || selectedDestinyConnection == ''"
                        :optionSelected="selectedDestinyObject"
                        :options="objectsDestinyList"
                        @input="(value) => {selectedDestinyObject = value; selectedDestinyObject != null && filterFields(selectedDestinyObject.value, 'destiny')}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>
            </b-row>
        </div>
    </b-card>
</template>

<script lang="js">

import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import { mapGetters, mapMutations, mapActions } from "vuex";
import Ripple from 'vue-ripple-directive';
import {
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        SingleSelect
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        editingData: {
            type: Object,
            default: {}
        },
        connectionsListOrigin: {
            type: Array,
            default:null
        },
        connectionsListDestiny: {
            type: Array,
            default:null
        }
    },
    watch: {
        selectedOriginConnection(v){
            if(v) {
                this.enableIboxByConnection();
                this.setOriginAndDestinySelected();
            }
            if(!this.flagEditActivity) this.selectedOriginObject = null;
            this.checkFields();
        },
        selectedOriginObject(){
            this.checkFields();
        },
        selectedDestinyConnection(v){
            if(v) {
                this.enableIboxByConnection();
                this.setOriginAndDestinySelected();
            }

            if(!this.flagEditActivity) this.selectedDestinyObject = null;
            this.checkFields();
        },
        selectedDestinyObject(){
            this.checkFields();
        },
        flagCleanFields(v) {
            if(v) this.cleanFields();
        },
        flagIntegrationAction(v) {
            if(!v) this.cleanFields();
            if(v && this.flagEditActivity) this.setDataForEditing();
        },
        flagActivityButton(v) {
            if(v && this.flagIntegrationAction) this.saveData();
        }
    },
    mounted() {
        if(this.flagIntegrationAction && this.flagEditActivity) this.setDataForEditing();
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagActivityButton',
                'flagCleanFields',
                'flagEditActivity',
                'flagIntegrationAction',
                'selectedPerformedVia',
                'selectedAction'
            ]
        )
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedOriginConnection: null,
            selectedOriginObject: null,
            selectedDestinyConnection: null,
            selectedDestinyObject: null,
            flagValidOriginConnection: null,
            flagValidOriginObject: null,
            flagValidDestinyConnection: null,
            flagValidDestinyObject: null,
            flagActivityEqual:null,
            objectsOriginList:[],
            objectsDestinyList:[],
            connectionFilterList:[],
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
                'UPDATE_FLAG_INTEGRATION_VALIDATION',
                'UPDATE_FLAG_RETURN_FIELDS_IBOX',
                'UPDATE_FLAG_IDENTIFICATION_KEY_IBOX',
                'UPDATE_FLAG_ROUTINE_BEHAVIOR_IBOX',
                'UPDATE_FLAG_PROCEDURES_IBOX',
                'UPDATE_FLAG_LOTS_IBOX'
            ]
        ),
        ...mapActions('flags_controller_pipelines', [
            'resetAfterMapIBox'
        ]),
        enableIboxByConnection() {
            if(this.selectedOriginConnection && this.selectedDestinyConnection) {
                let selectedAction = this.selectedAction;
                let performedVia = this.selectedPerformedVia;
                let typeOrigin = this.connectionsListOrigin.find(item => item.value == this.selectedOriginConnection.value).type;
                let typeDestiny = this.connectionsListDestiny.find(item => item.value == this.selectedDestinyConnection.value).type;

                this.resetAfterMapIBox();

                //  API DEFAULT 1 - SQL 2 - SAP 3 - CSV 4 - REST 5
                if(
                    (typeOrigin == 2 && typeDestiny == 2)
                    || (typeOrigin == 4 && typeDestiny == 2)
                    || (typeOrigin == 3 && typeDestiny == 2)
                    || (typeOrigin == 5 && typeDestiny == 2)
                    || (typeOrigin == 1 && typeDestiny == 2)
                ) {
                    this.UPDATE_FLAG_IDENTIFICATION_KEY_IBOX(true);
                    this.UPDATE_FLAG_ROUTINE_BEHAVIOR_IBOX(true);
                    this.UPDATE_FLAG_PROCEDURES_IBOX(true);

                } else if(
                    (typeOrigin == 2 && typeDestiny == 4)
                    || (typeOrigin == 1 && typeDestiny == 4)
                ) {
                    this.UPDATE_FLAG_PROCEDURES_IBOX(true);

                } else if(
                    (typeOrigin == 2 && typeDestiny == 3)
                    || (typeOrigin == 1 && typeDestiny == 3)
                ) {
                    this.UPDATE_FLAG_RETURN_FIELDS_IBOX(true);
                    this.UPDATE_FLAG_PROCEDURES_IBOX(true);

                } else if(typeOrigin == 2 && typeDestiny == 5) {
                    if(selectedAction == 2 && performedVia != 1 ) this.UPDATE_FLAG_LOTS_IBOX(true);
                    this.UPDATE_FLAG_PROCEDURES_IBOX(true);

                } else if(typeOrigin == 4 && typeDestiny == 3) {
                    this.UPDATE_FLAG_RETURN_FIELDS_IBOX(true);

                } else if(typeOrigin == 4 && typeDestiny == 5) {
                    this.UPDATE_FLAG_LOTS_IBOX(true);
                }
            }
        },
        setOriginAndDestinySelected() {
            if(this.selectedOriginConnection && this.selectedDestinyConnection) {
                let typeOrigin = this.connectionsListOrigin.find(item => item.value == this.selectedOriginConnection.value).type;
                let typeDestiny = this.connectionsListDestiny.find(item => item.value == this.selectedDestinyConnection.value).type;

                //  API DEFAULT 1 - SQL 2 - SAP 3 - CSV 4 - REST 5
                this.$emit('setOriginAndDestinySelected', {typeOrigin: typeOrigin, typeDestiny: typeDestiny})
            }
        },
        filterFields(idObject, type){

            if (type == 'origin'){
                this.$emit('atualizafieldsOrigin', this.objectsOriginList.filter(item => item.value == idObject)[0]?.fields.map(field => {return {value:field.id, text:field.field_name, type:field.field_type}}));
            }
            else{
                this.$emit('atualizafieldsDestiny', this.objectsDestinyList.filter(item => item.value == idObject)[0]?.fields.map(field => {return {value:field.id, text:field.field_name, type:field.field_type}}));
            }
        },
        filterObjects(idConnection, type) {
            if (type == 'origin') {
                let objectsOrigin = this.connectionsListOrigin.filter(item => item.value == idConnection)[0].objects.map(object => {return {value:object.id, text:object.name, fields:object.fields}})
                this.objectsOriginList = objectsOrigin;
            }
            else {
                let objectsDestiny = this.connectionsListDestiny.filter(item => item.value == idConnection)[0].objects.map(object => {return {value:object.id, text:object.name, fields:object.fields}})
                this.objectsDestinyList = objectsDestiny;
            }
        },
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let data = {
                    source_object: this.selectedOriginObject.value,
                    destination_object: this.selectedDestinyObject.value
                }

                this.$emit('setValue', data);
            }
        },
        validateFields() {
            this.flagValidOriginConnection = this.selectedOriginConnection != null ? null : false;
            this.flagValidOriginObject = this.selectedOriginObject != null ? null : false;
            this.flagValidDestinyConnection = this.selectedDestinyConnection != null ? null : false;
            this.flagValidDestinyObject = this.selectedDestinyObject != null ? null : false;

            let validate = this.flagValidOriginConnection == false || this.flagValidOriginObject == false ||
            this.flagValidDestinyConnection || this.flagValidDestinyObject ? false : true;

            this.UPDATE_FLAG_INTEGRATION_VALIDATION(validate);

            return validate;
        },
        checkFields() {
            if(this.selectedOriginConnection != '' && this.selectedOriginConnection != null
            && this.selectedOriginObject != '' && this.selectedOriginObject != null
            && this.selectedDestinyConnection != '' && this.selectedDestinyConnection != null
            && this.selectedDestinyObject != '' && this.selectedDestinyObject != null
            ) {
                this.$emit('showIBox', 'fields-mapping');
            } else if(this.flagIntegrationAction){
                this.$emit('showIBox', 'integration')
            }
        },
        cleanFields() {
            this.selectedOriginConnection = null;
            this.selectedDestinyConnection = null;
            this.selectedOriginObject = null;
            this.selectedDestinyObject = null;
            this.flagValidOriginConnection = null;
            this.flagValidOriginObject = null;
            this.flagValidDestinyConnection = null;
            this.flagValidDestinyObject = null;
        },
        setDataForEditing() {
            this.selectedOriginConnection = this.connectionsListOrigin.find(item1 => item1.objects.find(item2 => item2.id == this.editingData.activity.integrations.source_object) != undefined);
            this.filterObjects(this.selectedOriginConnection.value, 'origin');
            this.selectedOriginObject = this.objectsOriginList.find(item => item.value == this.editingData.activity.integrations.source_object);

            this.selectedDestinyConnection = this.connectionsListDestiny.find(item1 => item1.objects.find(item2 => item2.id == this.editingData.activity.integrations.destination_object) != undefined);
            this.filterObjects(this.selectedDestinyConnection.value, 'destiny');
            this.selectedDestinyObject = this.objectsDestinyList.find(item => item.value == this.editingData.activity.integrations.destination_object);

            this.filterFields(this.selectedOriginObject.value, 'origin');
            this.filterFields(this.selectedDestinyObject.value, 'destiny');

            this.checkFields();
        },
    },
}
</script>

<style lang="scss">
    #card-activity-integration {
        overflow-x: visible;

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
        }
    }
</style>
