<template>
    <b-card id="card-activity-routine">
        <div class="container-card-header">
            <span class="title">
                {{ $t('IntegrationPage.RoutineBehaviour') }}
            </span>
        </div>

        <div class="alert-routine d-flex justify-content-between align-items-center" v-if="!flagValidCheckboxs">
            <span>
                {{ $t('IntegrationPage.AlertSelectOption') }}
            </span>
            <CloseIcon @click="() =>flagValidCheckboxs = !flagValidCheckboxs"/>
        </div>

        <b-col>
            <b-row cols="12" class="container-checkboxs">
                <b-row class="col-12 p-0">
                    <div class="col-12 col-md-6">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                switch
                                v-model="cleanChecked"
                                id="routine-clean"
                                @change="validaSwitchs()"
                            />
                            <span class="checkbox-title">
                                {{ $t('Clear') }}
                            </span>
                        </div>
                        <div class="checkbox-subtitle">
                            <span>
                                {{ $t('IntegrationPage.CleanSubtitle') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                switch
                                v-model="deleteChecked"
                                id="routine-delete"
                                @change="cleanChecked = false"
                            />
                            <span class="checkbox-title">
                                {{ $t('IntegrationPage.Delete') }}
                            </span>
                        </div>
                        <div class="checkbox-subtitle">
                            <span>
                                {{ $t('IntegrationPage.DeleteSubtitle') }}
                            </span>
                        </div>
                    </div>
                </b-row>
                <b-row class="col-12 p-0">
                    <div class="col-12 col-md-6">
                        <div>
                            <div class="d-flex align-items-center">
                                <b-form-checkbox
                                    switch
                                    v-model="insertChecked"
                                    id="routine-insert"
                                />
                                <span class="checkbox-title">
                                    {{ $t('IntegrationPage.Insert') }}
                                </span>
                            </div>
                            <div class="checkbox-subtitle">
                                <span>
                                    {{ $t('IntegrationPage.InsertSubtitle') }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                switch
                                v-model="updateChecked"
                                id="routine-update"
                                @change="cleanChecked = false"
                            />
                            <span class="checkbox-title">
                                {{ $t('IntegrationPage.Update') }}
                            </span>
                        </div>
                        <div class="checkbox-subtitle">
                            <span>
                                {{ $t('IntegrationPage.UpdateSubtitle') }}
                            </span>
                        </div>
                    </div>
                </b-row>
            </b-row>
        </b-col>
    </b-card>
</template>

<script lang="js">

import CloseIcon from '@/assets/images/icons/close-icon-small-2.svg';
import Ripple from 'vue-ripple-directive';
import { mapGetters, mapMutations } from "vuex";
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    VBToggle,
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BCard,
        CloseIcon
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        editingData: {
            type: Object,
            default: {}
        }
    },
    watch: {
        flagCleanFields(v) {
            if(v) this.resetCheckboxs();
        },
        flagActivityButton(v){
            if(v && this.flagRoutineBehaviorIbox && this.flagIntegrationAction) this.saveData();
        },
        flagAfterMapIboxs(v) {
            if(!v) this.resetCheckboxs();
            if(v && this.flagEditActivity && this.flagRoutineBehaviorIbox) this.setDataForEditing();
        }
    },
    mounted() {
        if (this.flagAfterMapIboxs && this.flagEditActivity && this.flagRoutineBehaviorIbox) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            cleanChecked: true,
            deleteChecked: false,
            insertChecked: true,
            updateChecked: false,
            flagValidCheckboxs: true
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagCleanFields',
            'flagEditActivity',
            'flagAfterMapIboxs',
            'flagRoutineBehaviorIbox',
            'flagIntegrationAction'
        ]),
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_ROUTINE_BEHAVIOR_VALIDATION'
        ]),
        validaSwitchs(){
            if(this.cleanChecked){
                this.deleteChecked = false;
                this.updateChecked = false
            }
        },
        validateCheckboxs() {
            this.flagValidCheckboxs = this.cleanChecked == false && this.deleteChecked == false && this.insertChecked == false && this.updateChecked == false ? false : true;

            let validate = this.flagValidCheckboxs;
            this.UPDATE_FLAG_ROUTINE_BEHAVIOR_VALIDATION(validate);

            return validate;
        },
        saveData() {
            let validate = this.validateCheckboxs();

            if(validate) {
                let data = {
                    clean: this.cleanChecked,
                    add: this.insertChecked,
                    delete: this.deleteChecked,
                    update: this.updateChecked
                }

                this.$emit('setValue', data);
            }
        },
        resetCheckboxs() {
            this.cleanChecked = true,
            this.deleteChecked = false,
            this.insertChecked = true,
            this.updateChecked = false,
            this.flagValidCheckboxs = true;
        },
        setDataForEditing() {
            this.cleanChecked = this.editingData.activity.routinesBehavior.clean;
            this.deleteChecked = this.editingData.activity.routinesBehavior.delete;
            this.insertChecked = this.editingData.activity.routinesBehavior.add;
            this.updateChecked = this.editingData.activity.routinesBehavior.update;
        }
    },
}
</script>

<style lang="scss">
    #card-activity-routine {
        overflow-x: visible;

        .card-body {
            padding: 16px
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
        }

        .alert-routine {
            border: 1px solid #EF5350;
            padding: 16px;
            border-radius: 6px;
            background-color: #FFEBEE;
            margin-bottom: 17px;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #B71C1C;

            }
            svg {
                cursor: pointer;
            }
        }

        .container-checkboxs {
            .custom-control-input:checked ~ .custom-control-label::before {
                border-color: #974900;
                background-color: #974900;
            }

            .custom-switch .custom-control-label::before {
                border-color: #ECE0DB;
                background-color: #ECE0DB;
            }

            .custom-switch .custom-control-label:after {
                top: 4.3px;
            }

            .checkbox-title {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4C4541;
            }

            .checkbox-subtitle {
                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998F8A;
                }
                padding: 8px 0 16px;
            }
        }
    }
</style>
