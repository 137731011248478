<template>
    <div id="container-activity-session">

        <LinkedActivity
            ref="linkedActivity"
            v-show="flagActivitiesIbox"
            :linkedActivityList="linkedActivityList"
            :linkedActivityListOfRoute="linkedActivityListOfRoute"
            @updateLinkedActivityList="(value) => linkedActivityList = value"
            @changeCase="(value) => changeCase(value)"
            @setGroupForAddActivity="(value) => groupForAddActivity = value"
            @setActivityForEditing="(value) => setActivityForEditing(value)"
            @showIBox="(value) => showIBox(value)"
            @hasData="showIBox('linked-activity')"
            @deleteActivityOrActivityGroup="(value) => deleteActivityOrActivityGroup(value)"
        />

        <div id="new-activity" v-show="flagNewActivityIbox && !flagShowOverlay">
            <NewActivity
                ref="newActivity"
                :actionsOptionsList="actionsList"
                :editingData="editingData"
                :groupForAddActivity="groupForAddActivity"
                :linkedActivityList="linkedActivityList"
                :selectedExecutionType="selectedExecutionType"
                @resetGroupForAddActivity="groupForAddActivity = ''"
                @showIBoxs="(value) => showIBoxs(value)"
                @showIBox="(value) => showIBox(value)"
                @setValue="(value) => activity.infos = value"
                @setGroup="(value) => groupName = value"
                @activityID="(value) => oldActivityID = value"
                @activityGroupID="(value) => activityGroupID = value"
            />
            <!-- Executar integração -->
            <div v-if="!firstLoading">
                <div id="integration-action" v-show="flagIntegrationAction">
                    <Integration
                        v-if="flagIntegrationIbox"
                        :connectionsListOrigin="connectionsListOrigin"
                        :connectionsListDestiny="connectionsListDestiny"
                        :editingData="editingData"
                        @showIBox="(value) => showIBox(value)"
                        @showIBoxs="(value) => showIBoxs(value)"
                        @atualizafieldsOrigin="(value) => atualizaFields(value, 'origin')"
                        @atualizafieldsDestiny="(value) => atualizaFields(value, 'destiny')"
                        @setValue="(value) => activity.integrations = value"
                        @setOriginAndDestinySelected="(value) => originDestinySelected = value"
                    />

                    <CardOriginDestiny
                        :titleCard="$t('IntegrationPage.FieldsMapping')"
                        :cardID="'MapeamentodeCampos'"
                        :checkFieldsMode="true"
                        v-if="flagMappingFieldsIbox"
                        :fieldsMapOrigin="fieldsMapOrigin"
                        :fieldsMapDestiny="fieldsMapDestiny"
                        :editingData="editingData"
                        @setFieldMappingSelected="(value) => fieldMappingSelected = value"
                        @showIBox="(value) => showIBox(value)"
                        @atualizaReturnFields="(value) => atualizaReturnFields(value)"
                        @setValue="(value) => activity.fieldsMapping = value"
                    />

                    <!-- others -->
                    <div v-show="flagAfterMapIboxs">
                        <CardOriginDestinyReturn
                            v-if="flagReturnFieldsIbox"
                            :titleCard="$t('IntegrationPage.ReturnFields')"
                            :cardID="'CamposdeRetorno'"
                            :fieldsReturnOrigin="fieldsReturnOrigin"
                            :fieldsReturnDestiny="fieldsReturnDestiny"
                            :editingData="editingData"
                            @showIBox="(value) => showIBox(value)"
                            @setValue="(value) => activity.fieldsReturn = value"
                        />
                        <IdentificationKeys
                            v-if="flagIdentificationKeyIbox"
                            :editingData="editingData"
                            :fieldMappingSelected="fieldMappingSelected"
                            @setValue="(value) => activity.identificationKeys = value"
                        />
                        <RoutineBehavior
                            v-if="flagRoutineBehaviorIbox"
                            :editingData="editingData"
                            @setValue="(value) => activity.routinesBehavior = value"
                        />
                        <QuantityPerLots v-if="flagLotsIbox"
                            :editingData="editingData"
                            @setValue="(value) => activity.batchQuantity = value"
                        />
                        <StoredProcedureIntegration
                            v-if="flagProceduresIbox"
                            :editingData="editingData"
                            :originDestinySelected="originDestinySelected"
                            @setValue="(value) => activity.procedures = value"
                        />
                    </div>
                </div>

                <!-- Enviar relatório de integração -->
                <ShippingData
                    v-if="flagShippingAction"
                    :editingData="editingData"
                    :pipelinesList="pipelinesList"
                    @setValue="(value) => activity.emailServer = value"
                />

                <!-- Executar Azure Pipeline -->
                <AzureDataFactory
                    v-if="flagAzureDataFactoryAction"
                    :editingData="editingData"
                    :azureConnectionsList="azureConnectionsList"
                    @setValue="(value) => activity.azureDataFactory = value"
                />

                <RunStoredProcedure
                    v-if="flagRunProcedureAction"
                    :editingData="editingData"
                    :sqlConnectionsList="sqlConnectionsList"
                    @setValue="(value) => activity.runStoredProcedure = value"
                />

                <RunPowershell
                    v-if="flagRunPowerShellAction"
                    :editingData="editingData"
                    @setValue="(value) => activity.runPowerShell = value"
                />

                <RunPipeline
                    v-if="flagRunPipelineAction"
                    :editingData="editingData"
                    :pipelinesList="pipelinesList"
                    @setValue="(value) => activity.runPipeline = value"
                />
            </div>
        </div>

    </div>
</template>

<script lang="js">
  import Ripple from 'vue-ripple-directive';
  import NewActivity from '@/views/pages/integration/pipelines/activity-session/NewActivity.vue';
  import Integration from '@/views/pages/integration/pipelines/activity-session/Integration.vue';
  import CardOriginDestiny from '@/views/pages/integration/pipelines/activity-session/CardOriginDestiny.vue';
  import IdentificationKeys from '@/views/pages/integration/pipelines/activity-session/IdentificationKeys.vue';
  import RoutineBehavior from '@/views/pages/integration/pipelines/activity-session/RoutineBehavior.vue';
  import StoredProcedureIntegration from '@/views/pages/integration/pipelines/activity-session/StoredProcedureIntegration.vue';
  import ShippingData from '@/views/pages/integration/pipelines/activity-session/ShippingData.vue';
  import LinkedActivity from '@/views/pages/integration/pipelines/activity-session/LinkedActivity.vue';
  import CardOriginDestinyReturn from '@/views/pages/integration/pipelines/activity-session/CardOriginDestinyReturn.vue';
  import RunStoredProcedure from '@/views/pages/integration/pipelines/activity-session/run-stored-procedure/RunStoredProcedure.vue';
  import { ThumbsDownIcon } from 'vue-feather-icons';
  import {
    BCard,
    VBToggle,
    BButton
  } from 'bootstrap-vue';
import { _ } from '@/libs/acl/config';
import { mapGetters, mapMutations, mapActions } from "vuex";
import QuantityPerLots from './activity-session/QuantityPerLots.vue';
import AzureDataFactory from './activity-session/AzureDataFactory.vue';
import RunPowershell from './activity-session/run-powershell/RunPowershell.vue';
import RunPipeline from './activity-session/run-pipeline/RunPipeline.vue';

export default {
    components: {
    BCard,
    BButton,
    NewActivity,
    Integration,
    CardOriginDestiny,
    IdentificationKeys,
    RoutineBehavior,
    ShippingData,
    LinkedActivity,
    CardOriginDestinyReturn,
    ThumbsDownIcon,
    QuantityPerLots,
    AzureDataFactory,
    StoredProcedureIntegration,
    RunStoredProcedure,
    RunPowershell,
    RunPipeline
},
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            fieldsMapOrigin:[],
            fieldsMapDestiny:[],
            fieldsReturnOrigin:[],
            fieldsReturnDestiny:[],
            activity: {},
            groupName: '',
            linkedActivityList:[],
            editingData: {},
            oldActivityID: '',
            activityGroupID: '',
            groupForAddActivity: '',
            groupID: '',
            activityID: '',
            linkedActivityListOfRoute: [],
            fieldMappingSelected: [],
            originDestinySelected: null,
            selectedObject: null,
            flagValidSession: false,
            firstLoading: true,
        }
    },
    computed: {
       ...mapGetters('flags_controller_pipelines', [
            'flagActivitiesIbox',
            'flagNewActivityIbox',
            'flagIntegrationIbox',
            'flagMappingFieldsIbox',
            'flagReturnFieldsIbox',
            'flagIdentificationKeyIbox',
            'flagRoutineBehaviorIbox',
            'flagProceduresIbox',
            'flagLotsIbox',
            'flagNewActivityValidation',
            'flagIntegrationValidation',
            'flagMappingFieldsValidation',
            'flagReturnFieldsValidation',
            'flagIdentificationKeyValidation',
            'flagRoutineBehaviorValidation',
            'flagProceduresValidation',
            'flagLotsValidation',
            'flagShippingValidation',
            'flagAlertsValidation',
            'flagIntegrationAction',
            'flagShippingAction',
            'flagEditActivity',
            'flagFloatSaveButton',
            'flagAfterMapIboxs',
            'flagActivitiesValidation',
            'flagEditActivityFixed',
            'flagAlertsSessionValidation',
            'flagWaitReturnButton',
            'flagCleanFields',
            'flagAzureDataFactoryAction',
            'flagAzureDataFactoryValidation',
            'flagRunProcedureAction',
            'flagRunProcedureValidation',
            'flagRunPowerShellAction',
            'flagRunPowerShellValidation',
            'flagRunPipelineAction',
            'flagRunPipelineValidation',
            'flagAlertsSessionTab',
            'flagGetPipeline'
        ]),
        ...mapGetters('app', [
            'flagShowOverlay'
        ])
    },
    props: {
        actionsList:{
            type:Array,
            default:null
        },
        pipelinesList:{
            type:Array,
            default:null
        },
        pipeline: {
            type: Object,
            default: {}
        },
        selectedExecutionType: {
            type: String,
        },
        azureConnectionsList: {
            type: Array,
            default: null
        },
        sqlConnectionsList: {
            type: Array,
            default: []
        },
        connectionsListOrigin: {
            type: Array,
            default: null
        },
        connectionsListDestiny: {
            type: Array,
            default: null
        }
    },
    mounted() {
        setTimeout(() => {
            this.firstLoading = false;
        }, 5000);
    },
    watch: {
        pipeline() {
            this.setDataPipeline();
        },
        flagGetPipeline(v) {
            v && this.setDataPipeline();
        },
        linkedActivityList(value) {
            if(value.length == 0 || this.$route.params.action == 'new-activity') {
                this.showIBox('new-activity');
                this.UPDATE_LINKED_ACTIVITY_LIST_EMPTY(true);
            } else {
                    if(this.flagFloatSaveButton) {
                    this.validateTabByList();
                    this.saveData();
                }
                this.showIBox('linked-activity');
                this.UPDATE_LINKED_ACTIVITY_LIST_EMPTY(false);
            }
        },
        flagActivitiesIbox(v) {
            if(v && this.flagFloatSaveButton) {
                this.validateTabByList();
                this.saveData();
            }
        },
        //validation
        flagFloatSaveButton(v) {
            if(v && this.flagNewActivityIbox) {
                this.UPDATE_FLAG_ACTIVITY_BUTTON(true);
            } else if(v && this.flagActivitiesIbox) {
                this.validateTabByList();
                this.saveData();
            }
        },
        flagEditActivity(v) {
            if(v) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.UPDATE_FLAG_EDIT_ACTIVITY_FIXED(v);
                setTimeout(() => {
                    this.UPDATE_FLAG_EDIT_ACTIVITY(false);
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }, 3000);
            }
        },
        flagNewActivityValidation() {
            if(this.flagNewActivityIbox) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagIntegrationValidation() {
            if(this.flagIntegrationAction) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagMappingFieldsValidation() {
            if(this.flagMappingFieldsIbox) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagReturnFieldsValidation() {
            if(this.flagReturnFieldsValidation) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagIdentificationKeyValidation() {
            if(this.flagIdentificationKeyIbox) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagRoutineBehaviorValidation() {
            if(this.flagRoutineBehaviorIbox){
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagProceduresValidation() {
            if(this.flagProceduresIbox) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagLotsValidation() {
            if(this.flagLotsIbox) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagShippingValidation(){
            if(this.flagShippingAction) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagAlertsValidation(){
            if(this.flagWaitReturnButton) {
                this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
            }
        },
        flagAzureDataFactoryValidation() {
            if(this.flagAzureDataFactoryAction) this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
        },
        flagRunProcedureValidation() {
            if(this.flagRunProcedureAction) this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
        },
        flagRunPowerShellValidation() {
            if(this.flagRunPowerShellAction) this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
        },
        flagRunPipelineValidation(){
            if(this.flagRunPipelineAction) this.addAndEditActivity(this.flagEditActivityFixed ? 'edit' : 'add');
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION',
            'UPDATE_FLAG_NEW_ACTIVITY_IBOX',
            'UPDATE_FLAG_MAPPING_FIELDS_IBOX',
            'UPDATE_FLAG_ACTIVITIES_IBOX',
            'UPDATE_FLAG_EDIT_ACTIVITY',
            'UPDATE_FLAG_INTEGRATION_ACTION',
            'UPDATE_FLAG_SHIPPING_ACTION',
            'UPDATE_FLAG_ACTIVITY_BUTTON',
            'UPDATE_FLAG_CLEAN_FIELDS',
            'UPDATE_AFTER_MAP_IBOXS',
            'UPDATE_FLAG_EDIT_ACTIVITY_FIXED',
            'UPDATE_FLAG_INTEGRATION_IBOX',
            'UPDATE_CAN_LINK_ALERT',
            'UPDATE_LINKED_ACTIVITY_LIST_EMPTY',
            'UPDATE_FLAG_AZURE_DATA_FACTORY_ACTION',
            'UPDATE_FLAG_CLEAN_NEW_ACTIVITY',
            'UPDATE_FLAG_RUN_PROCEDURE_ACTION',
            'UPDATE_FLAG_RUN_POWERSHELL_ACTION',
            'UPDATE_FLAG_RUN_PIPELINE_ACTION',
        ]),
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        ...mapActions('flags_controller_pipelines', [
            'resetActionsFlag',
            'resetAfterMapIBox',
            'resetIntegrationsIbox',
            'resetIntegrationsValidation'
        ]),
        validateIboxAndValidation() {
            let validationList = [];

            if(this.flagActivitiesIbox && this.flagActivitiesValidation != null) {
                validationList.push(this.flagActivitiesValidation)
            } else if(this.flagActivitiesIbox && this.flagActivitiesValidation == null) {
                return null;
            };

            if(this.flagNewActivityIbox && this.flagNewActivityValidation != null) {
                validationList.push(this.flagNewActivityValidation)
            } else if(this.flagNewActivityIbox && this.flagNewActivityValidation == null) {
                return null;
            };

            if(this.flagIntegrationAction && this.flagIntegrationValidation != null) {
                validationList.push(this.flagIntegrationValidation)
            } else if(this.flagIntegrationAction && this.flagIntegrationValidation == null) {
                return null;
            };

            if(this.flagMappingFieldsIbox && this.flagMappingFieldsValidation != null) {
                validationList.push(this.flagMappingFieldsValidation)
            } else if(this.flagMappingFieldsIbox && this.flagMappingFieldsValidation == null) {
                return null;
            };

            if(this.flagReturnFieldsIbox && this.flagReturnFieldsValidation != null) {
                validationList.push(this.flagReturnFieldsValidation)
            } else if(this.flagReturnFieldsIbox && this.flagReturnFieldsValidation == null) {
                return null;
            };

            if(this.flagIdentificationKeyIbox && this.flagIdentificationKeyValidation != null) {
                validationList.push(this.flagIdentificationKeyValidation)
            } else if(this.flagIdentificationKeyIbox && this.flagIdentificationKeyValidation == null) {
                return null;
            };

            if(this.flagRoutineBehaviorIbox && this.flagRoutineBehaviorValidation != null) {
                validationList.push(this.flagNewActivityValidation)
            } else if(this.flagRoutineBehaviorIbox && this.flagRoutineBehaviorValidation == null) {
                return null;
            };

            if(this.flagProceduresIbox && this.flagProceduresValidation != null) {
                validationList.push(this.flagProceduresValidation)
            } else if(this.flagProceduresIbox && this.flagProceduresValidation == null) {
                return null;
            };

            if(this.flagLotsIbox && this.flagLotsValidation != null) {
                validationList.push(this.flagLotsValidation)
            } else if(this.flagLotsIbox && this.flagLotsValidation == null) {
                return null;
            };

            if(this.flagShippingAction && this.flagShippingValidation != null) {
                validationList.push(this.flagShippingValidation)
            } else if(this.flagShippingAction && this.flagShippingValidation == null) {
                return null;
            };

            if(this.flagWaitReturnButton && this.flagAlertsValidation != null){
                validationList.push(this.flagAlertsValidation)
            } else if(this.flagWaitReturnButton && this.flagAlertsValidation == null) {
                return null;
            };

            if(this.flagAzureDataFactoryAction && this.flagAzureDataFactoryValidation != null) {
                validationList.push(this.flagAzureDataFactoryValidation)
            } else if(this.flagAzureDataFactoryAction && this.flagAzureDataFactoryValidation == null) {
                return null;
            };

            if(this.flagRunProcedureAction && this.flagRunProcedureValidation != null) {
                validationList.push(this.flagRunProcedureValidation)
            } else if(this.flagRunProcedureAction && this.flagRunProcedureValidation == null) {
                return null;
            }

            if(this.flagRunPowerShellAction && this.flagRunPowerShellValidation != null) {
                validationList.push(this.flagRunPowerShellValidation)
            } else if (this.flagRunPowerShellAction && this.flagRunPowerShellValidation == null) {
                return null;
            }

            if(this.flagRunPipelineAction && this.flagRunPipelineValidation != null) {
                validationList.push(this.flagRunPipelineValidation)
            } else if (this.flagRunPipelineAction && this.flagRunPipelineValidation == null) {
                return null;
            }

            this.UPDATE_FLAG_ACTIVITY_BUTTON(false);
            let validate = !validationList.includes(false);
            if(!validate) this.UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION(validate);


            return validate;
        },
        validateTabByList() {
            this.flagValidSession = this.linkedActivityList.length > 0 ? true : false;
        },
        atualizaReturnFields(fields){
            this.fieldsReturnOrigin = fields.map(item => {return {value:item.origin_id, text:item.origin_name, type:item.origin_type}})
            this.fieldsReturnDestiny = fields.map(item => {return {value:item.destiny_id, text:item.destiny_name, type:item.destiny_type}})
        },
        atualizaFields(list, type){
            if (type == 'origin'){
                this.fieldsMapOrigin= list
            }
            else{
                this.fieldsMapDestiny = list
            }
        },
        createID(type, groupIndex = undefined) {
            let id;

            if(type == 'group') {
                id = `gp-${parseInt(Math.random() * this.linkedActivityList.length * 3)}`;

                if(this.linkedActivityList.find(item => item.id == id) != undefined) this.createID('group');

            }  else if(type == 'activity') {
                id = groupIndex != undefined
                    ? `act-${parseInt(Math.random() * this.linkedActivityList[groupIndex].activities.length * 3)}`
                    : `act-0`;

                if(groupIndex)
                    if(this.linkedActivityList[groupIndex].activities.find(item => item.id == id) != undefined)
                        this.createID('group', groupIndex);
            }

            return id;
        },
        addAndEditActivity(type) {
            let validate = this.validateIboxAndValidation();
            if(validate) {
                if(type == 'add') {
                    let linkedActivity = this.linkedActivityList.find(item => item.groupName == this.groupName);

                    this.activity.id = this.createID('activity');
                    if(linkedActivity == undefined) {
                        let object = {
                            id: this.createID('group'),
                            order: this.linkedActivityList.length,
                            groupName: this.groupName,
                            if_success: null,
                            if_error: null,
                            activities: [this.activity],
                        }
                        this.linkedActivityList.push(object);

                    } else {
                        let index = this.linkedActivityList.findIndex(item => item.id == linkedActivity.id);

                        this.activity.id = this.createID('activity', index);
                        this.linkedActivityList[index].activities.push(this.activity)
                    }


                } else if(type == 'edit') {
                    this.activity.id = this.editingData.activity.id;

                    let groupIndex = this.linkedActivityList.findIndex(item => item.id == this.editingData.activityGroup.id);
                    let activityIndex = this.linkedActivityList[groupIndex].activities?.findIndex(item => item.id == this.editingData.activity.id);

                    if(this.groupName == this.editingData.activityGroup.groupName) {
                        this.linkedActivityList[groupIndex].activities[activityIndex] = this.activity;
                    } else {
                        this.linkedActivityList[groupIndex].activities.splice(activityIndex, 1);

                        if(this.linkedActivityList[groupIndex].activities.length == 0) this.linkedActivityList.splice(groupIndex, 1);

                        this.addAndEditActivity('add');
                    }
                }

                this.activity = {};
                this.UPDATE_FLAG_CLEAN_FIELDS(true);
                // this.UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION(true);
                this.showIBox('linked-activity');
                this.linkedActivityList.forEach(group => group.activities.sort((a, b) => a.infos.activityName.localeCompare(b.infos.activityName)));
            }

            if(validate != null) this.resetIntegrationsValidation();
        },
        showIBoxs(action) {
            this.resetActionsFlag();

            if(action != 2) this.resetAfterMapIBox();

            if(action == 1) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_RUN_PROCEDURE_ACTION(true);
            } else if(action == 2) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_INTEGRATION_ACTION(true);
                this.UPDATE_FLAG_INTEGRATION_IBOX(true);
            } else if(action == 3) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_AZURE_DATA_FACTORY_ACTION(true);
            } else if(action == 4) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_RUN_POWERSHELL_ACTION(true);
            }
            else if(action == 5) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_RUN_PIPELINE_ACTION(true);
            }
            else if(action == 6) {
                this.resetIntegrationsIbox();
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_SHIPPING_ACTION(true);
            }
        },
        showIBox(box) {
            this.resetIntegrationsIbox();

            if(box === 'fields-mapping') {
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_INTEGRATION_IBOX(true);
                this.UPDATE_FLAG_MAPPING_FIELDS_IBOX(true);

            } else if(box === 'integration') {
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_INTEGRATION_IBOX(true);

            } else if (box === 'others') {
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                this.UPDATE_FLAG_INTEGRATION_IBOX(true);
                this.UPDATE_FLAG_MAPPING_FIELDS_IBOX(true);
                this.UPDATE_AFTER_MAP_IBOXS(true);

            } else if(box === 'linked-activity') {
                this.resetActionsFlag();
                this.resetAfterMapIBox();
                this.UPDATE_FLAG_EDIT_ACTIVITY_FIXED(false);

                this.UPDATE_FLAG_CLEAN_FIELDS(true);
                setTimeout(() => {
                    this.UPDATE_FLAG_CLEAN_FIELDS(false);
                }, 2500);

                this.UPDATE_FLAG_ACTIVITIES_IBOX(true);
                this.UPDATE_FLAG_EDIT_ACTIVITY(false);

            } else if(box === 'new-activity') {
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);

                if(this.linkedActivityList.length > 0) this.UPDATE_CAN_LINK_ALERT(false);

            } else if (box === 'edit-activity') {
                this.UPDATE_FLAG_NEW_ACTIVITY_IBOX(true);
                let group = this.linkedActivityList.find(item => item.id === this.selectedObject.groupID);

                if (group && group.order == 0){
                    this.UPDATE_CAN_LINK_ALERT(true);
                }
                else{
                    this.UPDATE_CAN_LINK_ALERT(false);
                }
            }
        },
        saveData() {
            if(this.flagValidSession) {
                this.$emit('saveData', this.linkedActivityList);
            }

            this.UPDATE_FLAG_ACTIVITY_SESSION_VALIDATION(this.flagValidSession)
        },
        setActivityForEditing(object) {
            let activityGroup = this.linkedActivityList.find(item => item.id === object.groupID);
            let activity = activityGroup.activities.find(item => item.id === object.activityID);
            this.selectedObject = object;
            this.editingData = {activityGroup: activityGroup, activity: activity};
            this.$emit('setDataAlertsForEditing', {activityGroup: activityGroup, activity: activity});

            this.showIBox('edit-activity');
        },
        deleteActivityOrActivityGroup(object) {
            let groupIndex = this.linkedActivityList.findIndex(item => item.id == object.groupID);
            let activityIndex = this.linkedActivityList[groupIndex].activities.findIndex(item => item.id == object.activityID);

            if(object.type == 'activity-group') {
                this.linkedActivityList.splice(groupIndex, 1);
            } else if(object.type == 'activity') {
                if(this.linkedActivityList[groupIndex].activities.length == 1) {
                    this.linkedActivityList.splice(groupIndex, 1);
                } else {
                    this.linkedActivityList[groupIndex].activities.splice(activityIndex, 1);
                }
            }
        },
        setDataPipeline() {
            this.linkedActivityList = this.pipeline.pipeline_group;
            this.linkedActivityListOfRoute = this.pipeline.pipeline_group.map(item => item);
        },
        changeCase(object) {
            let groupIndex = this.linkedActivityList.findIndex(item => item.id == object.groupID);

            if(object.type == 'success') {
                this.linkedActivityList[groupIndex].if_success = object.selected;
            } else {
                this.linkedActivityList[groupIndex].if_error = object.selected;
            }
        },
        cleanFields() {
            this.$refs.newActivity.cleanFields();
        },
        setAlerts(object) {
            this.activity.alerts = object;
        },
        clearlinkedActivityList() {
            if(this.linkedActivityList.length > 0){
                this.linkedActivityList = [];
            }

            this.resetActivitySession();
            this.UPDATE_CAN_LINK_ALERT(true);
        },
        resetActivitySession() {
            this.showIBox('new-activity');
            this.UPDATE_FLAG_CLEAN_NEW_ACTIVITY(true);
            this.UPDATE_FLAG_EDIT_ACTIVITY_FIXED(false);
        }
    },
}
</script>

<style lang="scss">
    #container-activity-session {
        .form-group {
            legend, label {
                color: #4C4541 !important;
            }
        }

        input {
            &:focus {
                box-shadow: none !important;
                border-color: #974900 !important;
            }

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #CFC4BE;
            }

            &.is-invalid {
                border: 1px solid #D32F2F!important;

                &:focus {
                    border-color: #D32F2F !important;
                }
            }
        }

        .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
            border-color: #974900 !important;
        }

        .is-invalid-span {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .vs__search {
            border: 1px solid transparent !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .vs--open .vs__dropdown-toggle,
        .select2-container--default.select2-container--open .select2-selection--single
        {
            box-shadow: none !important;
            border-color: #974900 !important;
            input {
                border-color: transparent !important;
            }
        }
    }
</style>
