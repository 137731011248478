<template>
    <div :class="['form-group']" v-if="reRenderSelect">
        <div :class="['form-select2', state == false ? 'select2-invalid' : '']">
            <div 
                class="placeholder-select2"
                v-show="value == '' || value == null"
            >
                {{  placeholder }}
            </div>
            <select
                :id="id"
                :name="name"
                :value="value"
                :disabled="disabled"
                :class="['form-control form-select2']"
                @focus="focusEvent"
                v-if="!hasGroup"
            >
                <!-- select simples --> 
                <option
                    v-for="(item, index) in listagem"
                    :key="'item-s-' + index"
                    :value="item.value"
                    :selected="value == item.value"
                >
                    {{ item.text }}
                </option>
            </select>
            
            <!-- select com grupos -->
            <select
                :id="id"
                :name="name"
                :value="value"
                :disabled="disabled"
                :class="['form-control form-select2']"
                @focus="focusEvent"
                v-if="hasGroup"
            >
                <optgroup 
                    v-for="(group, index) in listagem"
                    :key="'group-' + index"
                    :selected="value == group.value"
                    :label="group.group"
                >
                    <option
                        v-for="(item, index) in group.options"
                        :key="'item-' + index"
                        :value="item.value"
                        :selected="value == item.value"
                    >
                        {{ item.text }} 
                    </option>l
                </optgroup>
            </select>
            <a
                @click="clearSelect"
                v-if="value !== '' && clear && !disabled"
                class="clear-select"
                >
            </a>
        </div>
        <span 
            class="is-invalid invalid-feedback d-block" 
            v-if="state == false"
        >
            {{ $t('RequiredField') }}
        </span>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: "form-select2",
    data() {
        return {
            reRenderSelect:true
        }
    },
    props: {
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        value: {
            default: "",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dropdownParent: {
            type: String,
            default: "body",
        },
        search: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "Selecione",
        },
        exit: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: true,
        },
        listagem: {
            type: Array,
            default: [],
        },
        noResults: {
            type: String,
            default: "Nenhum resultado encontrado",
        },
        hasGroup: {
            type: Boolean,
            default: false
        },
        state: {
            default: null,
        },
        confirmChange: {
            type: Boolean,
            default: false
        },
        conditionConfirmChange: {
            type: Boolean,
            default: false
        },
        keyAlertTitle:{
            type: String,
            default: "",
        },
        keyAlertText:{
            type: String,
            default: "",
        }
    },
    watch: {
        value: function (newValue, oldValue) {
            if (oldValue == "") {
                this.atualiza();
            }
        },
        listagem: function (newValue, oldValue) {
            if (oldValue !== newValue) {
                this.atualiza();
            }
            this.reRenderSelect = false
            this.$nextTick(() => {
                this.reRenderSelect = true
            });
        },
    },
    methods: {
        formatState(state) {
            if(state.text != 'Searching…') {
                let vm = this;
                let selectedValue = $(".form-select2#" + this.id).val();
                let $option = $("<span></span>");
                let $preview;

                if((selectedValue && state.id) && (selectedValue == state.id))
                    $preview = $(`<span data-opt="${state.id}" class="select2-item-remove">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4006_25886)">
                            <path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18 16.538L13.408 11.99L17.954 7.403L16.538 6L11.993 10.589L7.405 6.046L6 7.451L10.593 12.003L6.046 16.595L7.451 18L12.006 13.404L16.597 17.954L18 16.538Z" fill="#4C4541"/>
                        </g>
                        <clipPath id="clip0_4006_25886">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    `);
                else
                    $preview = $('<span data-opt="'+state.id+'" </span>');

                $preview.on('mouseup', function (e) {
                    e.stopPropagation();
                });

                $preview.on('click', function (e) {
                    let element = $(this).closest('.select2-results__option--selected');
                    element.removeClass('select2-results__option--selected');
                    $(".form-select2#" + vm.id).val("").trigger("change");
                    $(this).addClass('d-none');
                    vm.updateSelectValue(null);
                });

                $option.text(state.text);
                $option.append($preview);

                return $option;
            }
        },
        clearSelect() {
            $(".form-select2#" + this.id)
                .val("")
                .trigger("change");
        },
        updateSelectValue: function (value) {
            this.$emit("input", value);
            this.$emit("change");
        },
        focusEvent: function () {
            this.$emit("focus");
        },
        atualiza() {
            let vm = this;
            $(document).ready( () => {
                $(".form-select2#" + vm.id)
                    .select2({
                        minimumResultsForSearch: vm.search ? 0 : Infinity,
                        disabled: vm.disabled,
                        dropdownParent: $(vm.dropdownParent),
                        language: {
                            noResults: function () {
                                return vm.noResults;
                            },
                        },
                        templateResult: vm.formatState,
                    })
                    .on("select2:selecting", function (e) {
                        if (vm.confirmChange && vm.conditionConfirmChange) {
                            e.preventDefault();

                            vm.$swal({
                                title: vm.$t(vm.keyAlertTitle),
                                text: vm.$t(vm.keyAlertText),
                                showCancelButton: true,
                                showCloseButton: true,
                                cancelButtonText: vm.$t('MySites.cancel'),
                                confirmButtonText: vm.$t('Submit'),
                                customClass: {
                                    container: 'swal-conection',
                                    confirmButton: 'btn btn-primary',
                                    cancelButton: 'btn btn-outline-danger ml-1',
                                },
                                buttonsStyling: false,
                            }).then(result => {
                                if (result.value) {
                                    vm.updateSelectValue(e.params.args.data.id);
                                    $(".form-select2#" + vm.id).val(e.params.args.data.id).trigger("change");
                                }
                            })  
                        } else 
                            vm.updateSelectValue(e.params.args.data.id);
                    });
            });

            $(".form-select2#" + vm.id).one("select2:open", function (e) {
                $("input.select2-search__field").prop(
                    "placeholder",
                    "Pesquise aqui"
                );
            });

            $(".form-select2#" + vm.id).on("select2:closing", ()=> {
                this.atualiza();
            });

            document
                .getElementsByClassName("modal-content")[0]
                // .removeAttribute("tabindex");
        },
    },
    mounted() {
        this.atualiza(); 
    },
};
</script>

<style lang="scss">
.form-select2 {
    position: relative;

    .placeholder-select2 {
        position: absolute;
        z-index: 2;
        top: 11px;
        left: 16px;
        color: #CFC4BE !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2em;
    }

    &.select-error {
        .select2-container--default .select2-selection--single {
            border-color: #ed5666 !important;
        }
    }
    position: relative;
    .clear-select {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        .icon-clear-select {
            width: 11px;
            height: auto;
            border-radius: 100%;
            &.icon-clear-select:hover {
                background-color: #8d6e63;
                path:nth-child(2) {
                    fill: #fff;
                }
            }
        }
    }
}

.select2-invalid {
    .select2-selection.select2-selection--single {
        border-color: #ea5455 !important;
    }
}

.select2-container--default .select2-search--dropdown{
    padding: 20px 21px 12px 21px !important;
}
.select2-search__field {
    border-radius: 3px;
}
.select2-search__field::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #CFC4BE;
}

.select2-selection__rendered {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6e6b7b !important;
    padding-right: 35px !important;
}
.select2-results__option {
    color: #373737;
    font-size: 14px;
    line-height: 17px;

    .select2-results__option--selected {
        background-color: #974900 !important;
        color: #FFF !important;
        &.select2-results__option--selected:hover {
            background-color: #974900 !important;
            color: #fff !important;
        }
    }
}

.select2-container {
    width: 100% !important;
}
.select2-container--open {
    .select2-dropdown--below {
        margin-top: 2px;
        border-top: 1px solid #aaa;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
            0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .select2-dropdown {
        border-radius: 3px;
    }
}
.select2-selection {
    &.select2-selection--single {
        border-bottom-left-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }
    position: relative;
    .select2-selection__arrow {
        position: absolute;
        background-color: transparent;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.75'/%3E%3C/svg%3E");
        top: 0px !important;
        right: 14px;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 50%);
        background-position-y: 90%;
    }
    b {
        display: none !important;
    }
    .select2-selection__placeholder {
        color: #CFC4BE !important;
        font-size: 14px;
        line-height: 1.2em;
    }
}



.select2-results__group {
    padding: 0 !important;
    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color:#4C4541 !important;
        padding: 10px 14px;
    }
}


.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: transparent !important;
    &:hover{
        background: #FBEEE8 !important;
    
        span {
            color: #974900 !important;
        }

        .select2-item-remove {
            svg {
                path {
                    fill: #974900 !important;
                }
            }
        }
    
    }
    &:active {
        background-color: #FFDBC4 !important;
    }
}

.select2-container--default .select2-results__option--selected{
    &[aria-selected=true]{
        background-color: #974900 !important;
    }
    background-color: #974900 !important;
    span{
        color: #ffffff !important;
        svg{
            path{
                fill: #ffff !important;
            }
        }
    }
}


.select2-results__option--selectable{
    padding: 12px 18px !important;
    span{
        align-items: center;
        display: flex;
    }
}

.select2-results__options {
    margin-bottom: 0 !important;

    span{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #9F8D83;
        display: flex;
        justify-content: space-between;
    }
    
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;               /* width of the entire scrollbar */
        border-radius: 50%;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;   
        
      }
      &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        border: 10px solid transparent
      }
}

.swal-conection {
    .swal2-modal {
        padding: 0 !important;
        border-radius: 6px;
        width: 710px;

        .swal2-header {
            background-color: #D32F2F;
            padding: 20px 16px;
            border-radius: 6px 6px 0px 0px;
            flex-direction: row;
            justify-content: left;

            .swal2-title {
                color: #ffff;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                margin: 0;
            }

            .swal2-close {
                border-radius: 6px;
                width: 34px;
                background-color: #ffff;
                height: 34px;
                top: -5px;
                position: absolute;
                right: -5px;
            }
        }

        .swal2-content {
            padding: 20px 16px;

            .swal2-html-container {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4C4541;
                display: flex !important;

            }
        }

        .swal2-actions {
            padding: 0 16px 20px 16px;
            display: flex;
            justify-content: right;

            .swal2-confirm {
                margin-left: 10px !important;
                order: 2;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #ffff;
                border-color: #974900 !important;
                background-color: #974900 !important;
                border-radius: 5px;

                &:hover {
                    box-shadow: none !important;
                }
            }

            .swal2-cancel {
                order: 1;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #974900 !important;
                border-radius: 5px;
                border-color: transparent !important;

                &:hover {
                    background-color: #ffede1 !important;
                }
            }
        }
    }
}

</style>

