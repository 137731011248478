<template>
    <b-card id="card-alert-session">
        <div class="container-card-header">
            <span class="title">
                {{ $t('IntegrationPage.ReturnAlert') }}
            </span>
        </div>

        <b-row>
            <b-col cols="12" class="mb-1">
                <div class="d-flex align-items-center">
                    <b-form-checkbox switch v-model="form.frontend_wait_reply" />

                    <span class="checkbox-title">
                        {{ $t('IntegrationPage.WaitReturn') }}
                    </span>

                    <icon-info class="icon-info" id="tooltip-info" />

                    <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-info" triggers="hover blur" placement="topright">
                        <div class="info-tooltip-new-pipeline">
                            {{ $t('IntegrationPage.AlertInfo') }}
                        </div>
                    </b-tooltip>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="form.frontend_wait_reply">
            <b-col cols="12" md="4" lg="4" xl="4">
                <b-form-group label="Time Out" :invalid-feedback="$t('RequiredField')">
                    <b-form-input placeholder="00" v-model="form.frontend_timeout" :state="timeoutValid" type="number"
                        min="0" step=".01" oninput="validity.valid||(value='');" />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4" lg="4" xl="4">
                <b-form-group :invalid-feedback="$t('RequiredField')">
                    <template v-slot:label>
                        {{ $t('IntegrationPage.ValidationField') }}
                        <icon-info id="tooltip-valid-field" />

                        <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-valid-field" triggers="hover blur" placement="top">
                            <div class="info-tooltip-new-pipeline">
                                {{ $t('IntegrationPage.ValidationFieldInfo') }}
                            </div>
                        </b-tooltip>
                    </template>

                    <b-form-input :placeholder="$t('TypeHere')" v-model="form.return_field" :state="validationFieldValid" />
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4" lg="4" xl="4">
                <b-form-group :invalid-feedback="$t('RequiredField')">
                    <template v-slot:label>
                        {{ $t('IntegrationPage.ConsideredValidValue') }}
                        <icon-info id="tooltip-considered-valid-value" />

                        <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-considered-valid-value" triggers="hover blur" placement="top">
                            <div class="info-tooltip-new-pipeline">
                                {{ $t('IntegrationPage.ConsideredValidValueInfo') }}
                            </div>
                        </b-tooltip>
                    </template>

                    <b-form-input :placeholder="$t('TypeHere')" v-model="form.valid_response" :state="consideredValueValid"
                        id="input-activity" />
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-form-group :invalid-feedback="$t('RequiredField')">
                    <template v-slot:label>
                        {{ $t('IntegrationPage.MessageField') }}
                        <icon-info id="tooltip-message-field" />

                        <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-message-field" triggers="hover blur" placement="top">
                            <div class="info-tooltip-new-pipeline">
                                {{ $t('IntegrationPage.MessageFieldInfo') }}
                            </div>
                        </b-tooltip>
                    </template>

                    <b-form-input :placeholder="$t('TypeHere')" v-model="form.message_field" :state="messageFieldValid"
                        id="input-activity" />
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <div class="collapse-message">
                    <div class="collapse-header">
                        <span>
                            {{ $t('IntegrationPage.ValidCustomMessage') }}
                        </span>

                        <div class="div-message-tooltip">
                            <icon-info id="tooltip-valid-custom-message" />

                            <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-valid-custom-message" triggers="hover blur" placement="top">
                                <div class="info-tooltip-new-pipeline">
                                    {{ $t('IntegrationPage.ValidCustomMessageInfo') }}
                                </div>
                            </b-tooltip>
                        </div>

                        <div class="div-icon-toogle">
                            <icon-plus v-if="!validVisible" @click="validVisible = true" class="icon-plus" />
                            <icon-collapse-close v-if="validVisible" @click="validVisible = false" class="icon-plus" />
                        </div>
                    </div>

                    <b-collapse id="collapse-valid-message" class="mt-1" v-model="validVisible">
                        <b-row>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group :label="$t('IntegrationPage.AlertType')" class="mb-0">
                                    <SingleSelect id="input-valid-message" :placeholder="$t('Select')"
                                        :optionSelected="form.typeValidAlert" :options="setAlertTypeList()"
                                        @input="(value) => form.typeValidAlert = value">
                                    </SingleSelect>
                                </b-form-group>

                                <b-form-group :label="$t('IntegrationPage.Description')"
                                    :invalid-feedback="$t('RequiredField')" class="mt-1">
                                    <b-form-textarea :placeholder="$t('IntegrationPage.AlertDescription')"
                                        v-model="form.custom_valid_response" :disabled="!form.typeValidAlert"
                                        :state="validDescriptionState" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6" lg="6" xl="6" class="d-flex align-items-center justify-content-center">
                                <div class="toast-notification-example"
                                    :style="`background: ${getBackgroud(form.typeValidAlert ? form.typeValidAlert.value : null, true)};`">

                                    <icon-success v-if="form.typeValidAlert && form.typeValidAlert.value == 1" />
                                    <icon-error v-if="form.typeValidAlert && form.typeValidAlert.value == 2" />
                                    <icon-attention v-if="form.typeValidAlert && form.typeValidAlert.value == 3" />
                                    <icon-success v-if="form.typeValidAlert && form.typeValidAlert.value == 4" />
                                    <span v-if="form.typeValidAlert == null" class="icon-undefined">?</span>

                                    <div class="title-description">
                                        <span class="title">{{
                                            form.typeValidAlert ?
                                            (
                                                form.typeValidAlert.value == 1 ? $t('IntegrationPage.Success') :
                                                    form.typeValidAlert.value == 2 ? $t('IntegrationPage.Error') :
                                                        form.typeValidAlert.value == 3 ? $t('IntegrationPage.Attention') :
                                                            form.typeValidAlert.value == 4 ? $t('IntegrationPage.Information') :
                                                                $t('IntegrationPage.Title')
                                            ) : $t('IntegrationPage.Title')
                                        }}</span>

                                        <span v-if="!form.custom_valid_response" class="description">{{
                                            $t('IntegrationPage.AlertDescription')
                                        }} </span>
                                        <span else class="description">{{ form.custom_valid_response }} </span>
                                    </div>

                                    <icon-close class="ml-auto" />
                                </div>
                            </b-col>
                        </b-row>
                    </b-collapse>
                </div>
            </b-col>

            <b-col cols="12" class="mt-1 mb-2">
                <div class="collapse-message">
                    <div class="collapse-header">
                        <span>
                            {{ $t('IntegrationPage.InvalidCustomMessage') }}
                        </span>

                        <div class="div-message-tooltip">
                            <icon-info id="tooltip-invalid-custom-message" />

                            <b-tooltip custom-class="tooltip-new-pipeline" target="tooltip-invalid-custom-message" triggers="hover blur" placement="top">
                                <div class="info-tooltip-new-pipeline">
                                    {{ $t('IntegrationPage.InvalidCustomMessageInfo') }}
                                </div>
                            </b-tooltip>
                        </div>

                        <div class="div-icon-toogle">
                            <icon-plus class="icon-plus" v-if="!invalidVisible" @click="invalidVisible = true" />
                            <icon-collapse-close v-if="invalidVisible" @click="invalidVisible = false" class="icon-plus" />
                        </div>

                    </div>

                    <b-collapse id="collapse-invalid-message" class="mt-1" v-model="invalidVisible">
                        <b-row>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group :label="$t('IntegrationPage.AlertType')" class="mb-0">

                                    <SingleSelect id="input-valid-message" :placeholder="$t('Select')"
                                        :optionSelected="form.typeInvalidAlert" :options="setAlertTypeList()"
                                        @input="(value) => form.typeInvalidAlert = value">
                                    </SingleSelect>
                                </b-form-group>

                                <b-form-group :label="$t('IntegrationPage.Description')"
                                    :invalid-feedback="$t('RequiredField')" class="mt-1">
                                    <b-form-textarea :placeholder="$t('IntegrationPage.AlertDescription')"
                                        v-model="form.custom_invalid_response" :disabled="!form.typeInvalidAlert"
                                        :state="invalidDescriptionState" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6" lg="6" xl="6" class="d-flex align-items-center justify-content-center">
                                <div class="toast-notification-example"
                                    :style="`background: ${getBackgroud(form.typeInvalidAlert ? form.typeInvalidAlert.value : null, true)};`">

                                    <icon-success v-if="form.typeInvalidAlert && form.typeInvalidAlert.value == 1" />
                                    <icon-error v-if="form.typeInvalidAlert && form.typeInvalidAlert.value == 2" />
                                    <icon-attention v-if="form.typeInvalidAlert && form.typeInvalidAlert.value == 3" />
                                    <icon-success v-if="form.typeInvalidAlert && form.typeInvalidAlert.value == 4" />
                                    <span v-if="form.typeInvalidAlert == null" class="icon-undefined">?</span>

                                    <div class="title-description">
                                        <span class="title">{{
                                            form.typeInvalidAlert ?
                                            (
                                                form.typeInvalidAlert.value == 1 ? $t('IntegrationPage.Success') :
                                                    form.typeInvalidAlert.value == 2 ? $t('IntegrationPage.Error') :
                                                        form.typeInvalidAlert.value == 3 ? $t('IntegrationPage.Attention') :
                                                            form.typeInvalidAlert.value == 4 ? $t('IntegrationPage.Information') :
                                                                $t('IntegrationPage.Title')
                                            ) : $t('IntegrationPage.Title')
                                        }}</span>

                                        <span v-if="!form.custom_invalid_response" class="description">{{
                                            $t('IntegrationPage.AlertDescription')
                                        }} </span>
                                        <span else class="description">{{ form.custom_invalid_response }} </span>
                                    </div>

                                    <icon-close class="ml-auto" />
                                </div>
                            </b-col>
                        </b-row>
                    </b-collapse>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script lang="js">
import Ripple from 'vue-ripple-directive';
import IconInfo from '@/assets/images/icons/info.svg';
import IconPlus from '@/assets/images/icons/plus.svg';
import FormSelect2 from '@core/components/form-select-2/FormSelect2.vue';
import IconSuccess from '@/assets/images/icons/icon-notification-success.svg';
import IconClose from '@/assets/images/icons/close-icon-notification.svg';
import IconError from '@/assets/images/icons/error-icon.svg';
import IconAttention from '@/assets/images/icons/attention-icon.svg';
import IconCollapseClose from '@/assets/images/icons/icon-collapse-close.svg';
import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import { mapGetters, mapMutations } from "vuex";

import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle,
    BButton,
    BFormCheckbox,
    BTooltip,
    BCollapse,
    BFormTextarea,
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BCard,
        BButton,
        BFormCheckbox,
        IconInfo,
        IconPlus,
        BTooltip,
        BCollapse,
        FormSelect2,
        BFormTextarea,
        IconSuccess,
        IconClose,
        IconError,
        IconAttention,
        IconCollapseClose,
        SingleSelect
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            waitReturn: false,
            timeoutValid: null,
            validationFieldValid: null,
            consideredValueValid: null,
            messageFieldValid: null,
            invalidDescriptionState: null,
            validDescriptionState: null,
            form: {
                frontend_wait_reply: false,
                frontend_timeout: '',
                return_field: '',
                valid_response: '',
                message_field: '',
                typeValidAlert: null,
                custom_valid_response: '',
                typeInvalidAlert: null,
                custom_invalid_response: ''
            },
            validVisible: false,
            invalidVisible: false,
            language: null,
        }
    },
    props: {
        editingData: {
            type: Object,
            default: {}
        },
        flagAlert: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        flagActivityButton(v) {
            if (v) this.saveAlert();
        },
        'form.frontend_wait_reply'(v) {
            this.UPDATE_FLAG_WAIT_RETURN_BUTTON(true);
            if (!v) this.cleanFields();
        },
        flagCleanFields(v) {
            if (v) this.cleanFields();
        },
        flagEditActivity(v) {
            if (v) this.setDataForEditing();
        },
        'form.typeValidAlert'(v) {
            if (!v) {
                this.form.custom_valid_response = null;
                this.form.typeValidAlert = null;
            }
        },
        'form.typeInvalidAlert'(v) {
            if (!v) {
                this.form.custom_invalid_response = null;
                this.typeInvalidAlert = null;
            }
        },
        language(v) {
            if(v) this.changeLanguageAlertTypesListOptions();
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagCleanFields',
            'flagEditActivity',
        ]
        )
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_ALERTS_SESSION_VALIDATION',
            'UPDATE_FLAG_WAIT_RETURN_BUTTON',
            'UPDATE_FLAG_ALERTS_VALIDATION',
            'UPDATE_CAN_LINK_ALERT'
        ]),
        getBackgroud(value, valid) {
            if (valid) {
                if (!value)
                    return '#CFC4BE';
                else if (value == 1)
                    return '#4CAF50';
                else if (value == 2)
                    return '#F44336'
                else if (value == 3)
                    return '#FF9800'
                else
                    return '#2196f3'
            }
        },
        validateFileds() {
            if (this.form.frontend_wait_reply) {
                this.timeoutValid = this.form.frontend_timeout ? null : false;
                this.validationFieldValid = this.form.return_field ? null : false;
                this.consideredValueValid = this.form.valid_response ? null : false;
                this.messageFieldValid = this.form.message_field ? null : false;
                this.validDescriptionState = this.form.typeValidAlert && this.form.custom_valid_response ? null : this.form.typeValidAlert && !this.form.custom_valid_response ? false : null;
                this.invalidDescriptionState = this.form.typeInvalidAlert && this.form.custom_invalid_response ? null : this.form.typeInvalidAlert && !this.form.custom_invalid_response ? false : null;

                let validate = (this.timeoutValid == false ||
                    this.validationFieldValid == false ||
                    this.consideredValueValid == false ||
                    this.messageFieldValid == false ||
                    this.validDescriptionState == false ||
                    this.invalidDescriptionState == false) ? false : true;

                this.UPDATE_FLAG_ALERTS_SESSION_VALIDATION(validate);
                this.UPDATE_FLAG_ALERTS_VALIDATION(validate);

                return validate;
            }

            this.UPDATE_FLAG_ALERTS_SESSION_VALIDATION(true);
            this.UPDATE_FLAG_ALERTS_VALIDATION(true);

            return true;
        },
        saveAlert() {
            let valid = this.validateFileds();

            if (valid) {
                let object = {
                    frontend_wait_reply: this.form.frontend_wait_reply,
                    frontend_timeout: this.form.frontend_timeout,
                    return_field: this.form.return_field,
                    valid_response: this.form.valid_response,
                    message_field: this.form.message_field,
                    typeValidAlert: this.form.typeValidAlert?.value,
                    custom_valid_response: this.form.custom_valid_response,
                    typeInvalidAlert: this.form.typeInvalidAlert?.value,
                    custom_invalid_response: this.form.custom_invalid_response
                }

                this.$emit('setValue', object);
            }

            if (valid && this.flagAlert)
                this.$emit('showActivities');
        },
        cleanFields() {
            this.form.frontend_wait_reply = false;
            this.form.frontend_timeout = '';
            this.form.return_field = '';
            this.form.valid_response = '';
            this.form.message_field = '';
            this.form.typeValidAlert = null;
            this.form.custom_valid_response = '';
            this.form.typeInvalidAlert = null;
            this.form.custom_invalid_response = '';
        },
        setDataForEditing() {
            if (this.editingData.activity.alerts) {
                let waitReply = this.editingData.activity.alerts.frontend_wait_reply == "1" ?
                    true
                    : this.editingData.activity.alerts.frontend_wait_reply == "0" ?
                        false
                        : this.editingData.activity.alerts.frontend_wait_reply;

                this.form.frontend_wait_reply = waitReply;
                this.form.frontend_timeout = this.editingData.activity.alerts.frontend_timeout;
                this.form.return_field = this.editingData.activity.alerts.return_field;
                this.form.valid_response = this.editingData.activity.alerts.valid_response;
                this.form.message_field = this.editingData.activity.alerts.message_field;
                this.form.typeValidAlert = this.setAlertTypeList().find(f => f.value == this.editingData.activity.alerts.typeValidAlert);
                this.form.custom_valid_response = this.editingData.activity.alerts.custom_valid_response;
                this.form.typeInvalidAlert = this.setAlertTypeList().find(f => f.value == this.editingData.activity.alerts.typeInvalidAlert);
                this.form.custom_invalid_response = this.editingData.activity.alerts.custom_invalid_response;
            }
        },
        setAlertTypeList() {
            this.language = localStorage.getItem('language');

            let list =   [
                { text: this.$t('IntegrationPage.Success'), value: 1 },
                { text: this.$t('IntegrationPage.Error'), value: 2 },
                { text: this.$t('IntegrationPage.Attention'), value: 3 },
                { text: this.$t('IntegrationPage.Information'), value: 4 }
            ];

            return list
        },
        changeLanguageAlertTypesListOptions() {
            if(this.form?.typeValidAlert) this.form.typeValidAlert = this.setAlertTypeList().find(item => item.value == this.form.typeValidAlert.value);
            if(this.form?.typeInvalidAlert) this.form.typeInvalidAlert = this.setAlertTypeList().find(item => item.value == this.form.typeInvalidAlert.value);
        }
    },
}
</script>

<style lang="scss">
#card-alert-session {
    .form-group{
        .col-form-label{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #4C4541;
        }
    }
    overflow-x: hidden;

    input {
        &:focus {
            box-shadow: none !important;
            border-color: #974900 !important;
        }

        &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }
        
        &.is-invalid {
            border: 1px solid #D32F2F !important;
            &:focus {
                border-color: #D32F2F !important;
            }
        }
    }

    textarea {
        &:disabled {
            background: #EEEEEE;
            border: 1px solid #7E7570;
            color: #7E7570;
    
            &::placeholder {
                color: #7E7570;
            }
        }

        &.is-invalid {
            border: 1px solid #D32F2F !important;
            &:focus {
                border-color: #D32F2F !important;
            }
        }
    }

    .invalid-feedback {
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #D32F2F;
        text-align: left;
    }

    .card-body {
        padding: 16px 16px 0px 16px;
    }

    .container-card-header {
        flex-direction: row;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #4C4541;
        }
    }

    #input-name-pip::placeholder {
        color: #CFC4BE;
    }

    .custom-control-input:checked~.custom-control-label::before {
        border-color: #974900;
        background-color: #974900;
    }

    .custom-switch .custom-control-label::before {
        border-color: #ECE0DB;
        background-color: #ECE0DB;
    }

    .custom-switch .custom-control-label:after {
        top: 4.3px;
    }

    .checkbox-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4C4541;
    }

    .icon-info {
        margin-left: 10px;
    }

    .teste {
        background: #FAFAFA;
        border: 1px solid #CFC4BE;
        border-radius: 6px;
    }

    .collapse-message {
        background: #FAFAFA;
        border: 1px solid #CFC4BE;
        border-radius: 6px;
        padding: 16px;

        .collapse-header {
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #4C4541
            }

            .div-message-tooltip {
                margin-right: 10px;
                margin-left: 12px;
            }

            .div-icon-toogle {
                margin-left: auto;
            }
        }

        .icon-plus {
            cursor: pointer;

            &:focus {
                outline: 0 !important;
            }
        }
    }

    .vs--open .vs__dropdown-toggle,
    .select2-container--default.select2-container--open .select2-selection--single {
        box-shadow: none !important;
        border-color: #974900 !important;

        input {
            border-color: transparent !important;
        }
    }

    .toast-notification-example {
        display: flex;
        align-items: flex-start;
        padding: 16px;
        gap: 16px;
        width: 366px;
        height: 82px;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .title-description {
            display: flex;
            flex-direction: column;

            .title {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: #FFFFFF;
            }

            .description {
                color: #FFFFFF;
            }
        }

        .icon-undefined {
            color: #FFFFFF;
            font-weight: 800;
            font-size: 25px;
            line-height: 22px;
        }
    }
}

@media (max-width:480px) {

    .checkbox-title {
        font-size: 12px;
    }

    .container-card-header {
        .title {
            font-size: 16px !important;
        }
    }

    .collapse-header {
        span {
            gap: 6px !important;
            font-size: 12px !important;
        }

        .div-message-tooltip {
            margin-left: auto !important;
        }

        .div-icon-toogle {
            margin-left: 0px !important;
        }
    }

    #card-alert-session {
        .container-card-header {
            flex-direction: column;

            .title {
                align-self: flex-start;
            }
        }
    }

    .btn-float {
        right: 16px !important;

        .icon-btn-float {
            padding: 11px !important;
            scale: 0.9;
        }
    }
}
</style>
