<template>
    <div>
        <b-card id="card-activity-session">
            <div class="container-card-header">
                <span class="title">
                    {{ !flagEditActivityFixed ? $t('IntegrationPage.NewActivity') :  $t('IntegrationPage.EditActivity') }}
                </span>

                <div class="container-buttons" :style="linkedActivityList.length == 0 ? 'display:none;' : ''">
                    <b-button
                        class="btn-activity btn-linked-activities"
                        @click="showLinkedActivity"
                    >
                        <span>
                            {{ $t('IntegrationPage.LinkedActivities') }}
                        </span>
                    </b-button>
                </div>
            </div>

            <b-col cols="12" class="p-0">
                <b-row>
                    <b-form-group
                        class="mb-0 col-12"
                    >
                    <VSelect
                        key="select-chips-new-activity"
                        :label="($t('IntegrationPage.ActivityGroup'))"
                        :label-for="'users'"
                        :placeholder="($t('TypeHere'))"
                        :valueSelect.sync="selectedActivityGroup"
                        :options="activitiyGroupOptions"
                        :stateField="flagValidActivitiesGroup"
                        @valueInput="(value) => selectedActivityGroup = value"
                    >
                        <span class='is-invalid-span' v-if="flagValidActivitiesGroup == false">{{$t('RequiredField')}}</span>
                    </VSelect>

                    </b-form-group>
                </b-row>

                <b-row>
                    <b-form-group
                        class="col-12 col-sm-6"
                        :label="$t('IntegrationPage.Activity')"
                        :invalid-feedback="flagActivityEqual ? $t('NameAlreadyExists') : $t('RequiredField')"
                    >
                        <b-form-input
                            :placeholder="$t('TypeHere')"
                            v-model="activityInput"
                            :state="flagValidActivity"
                            id="input-activity"
                        />
                    </b-form-group>
                    <b-form-group
                        :label="$t('IntegrationPage.Action2')"
                        class="col-12 col-sm-6"
                    >
                        <SingleSelect
                            id="input-action"
                            :placeholder="$t('Select')"
                            :state="flagValidAction"
                            :optionSelected="selectedAction"
                            :options="setListAndChangeOptionLanguage().filter(item => selectedExecutionType == 1 ? item.value != 3 ? item : '' : item )"
                            @input="(value) => selectedAction = value"
                        >
                            {{ $t('RequiredField') }}
                        </SingleSelect>
                    </b-form-group>
                </b-row>
            </b-col>
        </b-card>
    </div>
</template>

<script lang="js">

import LinkedActivity from '@/views/pages/integration/pipelines/activity-session/LinkedActivity.vue';
import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import VSelect from '@/@core/components/v-select/VSelect.vue'
import Ripple from 'vue-ripple-directive';
import { mapGetters, mapMutations } from "vuex";
import {
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle,
    BButton,
    BCol
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        BButton,
        BCol,
        LinkedActivity,
        VSelect,
        SingleSelect
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagActivityButton',
                'flagCleanFields',
                'flagEditActivity',
                'flagEditActivityFixed',
                'flagCleanNewActivity',
                'setOldValueSelectedActivityGroup'
            ]
        )
    },
    props: {
        actionsOptionsList:{
            type:Array,
            default:null
        },
        editingData: {
            type: Object,
        },
        groupForAddActivity: {
            type: String,
            default: ''
        },
        linkedActivityList: {
            type: Array,
            default: []
        },
        selectedExecutionType: {
            type: String
        },
    },
    watch: {
        linkedActivityList(){
            this.activitiyGroupOptions = this.linkedActivityList.map(item => {return {label:item.groupName, value:item.groupName}})

        },
        flagEditActivity(v) {
            if(v) {
                this.setDataForEditing();
            }
        },
        "selectedAction.value"(v) {
            if(v) {
                this.showIBoxs(this.selectedAction.value);
            } else {
                this.showIBoxs();
            }

            this.UPDATE_SELECTED_ACTION(v);
        },
        flagCleanFields(v) {
            if(v) this.cleanFields();
        },
        groupForAddActivity: {
            handler (v) {
                if(v) {
                    this.setGroupForAddActivity();
                }
            },
            immediate: true
        },
        flagActivityButton(v) {
            if(v) this.saveData();
        },
        flagCleanNewActivity(v) {
            if(v) this.cleanFields();
        },
        selectedActivityGroup(v) {
            this.UPDATE_SELECTED_ACTIVITY_GROUP(this.selectedActivityGroup);
        },
        setOldValueSelectedActivityGroup(v) {
            if(v !== false) this.selectedActivityGroup = v;
        }
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedActivityGroup: null,
            activitiyGroupOptions: [],
            activityInput: '',
            selectedAction: null,
            flagValidActivitiesGroup: null,
            flagActivityEqual: null,
            flagValidActivity: null,
            flagValidAction: null,
            oldActivityID: '',
            groupID: '',
            group:null,
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
                'UPDATE_FLAG_NEW_ACTIVITY_VALIDATION',
                'UPDATE_SELECTED_ACTION',
                'UPDATE_FLAG_CLEAN_NEW_ACTIVITY',
                'UPDATE_SELECTED_ACTIVITY_GROUP'
            ]
        ),
        showIBoxs(value) {
            this.$emit('showIBoxs', value);
        },
        saveData() {
            let validate = this.validateFields();

            this.$emit('setGroup', this.selectedActivityGroup?.label)

            if(validate) {
                let infos = {
                    activityName: this.activityInput,
                    action: this.selectedAction.value,
                };

                this.$emit('setValue', infos);
            }
        },
        showLinkedActivity() {
            this.showIBoxs()
            this.cleanFields();
            this.$emit('showIBox', 'linked-activity')
            this.$emit('showBtn', true);
        },
        cleanFields() {
            this.flagValidAction = null;
            this.flagValidActivitiesGroup = null;
            this.flagValidActivity = null;
            this.selectedAction = null;
            this.selectedActivityGroup = null;
            this.activityInput = null;

            this.UPDATE_FLAG_CLEAN_NEW_ACTIVITY(false);
        },
        validateFields() {
            let nameEqual = false;

            this.linkedActivityList.forEach(item1 => item1.activities.forEach(item2 => {if(!nameEqual) {nameEqual = item2.infos.activityName == this.activityInput}} ));

            if(!this.flagEditActivityFixed) {
                this.flagActivityEqual = nameEqual;
            } else {
                this.flagActivityEqual = nameEqual && this.activityInput != this.oldActivityID ? true : false;
            }

            this.flagValidActivitiesGroup = this.selectedActivityGroup != null ? null : false;
            this.flagValidActivity = this.activityInput != '' && this.activityInput != null && this.flagActivityEqual == false ? null : false;

            this.flagValidAction = !this.selectedAction ? false : null;

            let validate = this.flagValidActivitiesGroup == false || this.flagValidActivity == false || this.flagValidAction == false ? false : true;

            this.UPDATE_FLAG_NEW_ACTIVITY_VALIDATION(validate);

            return validate;
        },
        setDataForEditing() {
            this.oldGroupID = this.editingData.activityGroup.groupName;
            this.oldActivityID = this.editingData.activity.infos.activityName
            this.selectedActivityGroup = {value: this.editingData.activityGroup.groupName, label: this.editingData.activityGroup.groupName}
            this.activityInput = this.editingData.activity.infos.activityName
            this.selectedAction = this.actionsOptionsList.find(item => item.value == this.editingData.activity.infos.action);
        },
        setGroupForAddActivity() {
            this.selectedActivityGroup = {value:this.groupForAddActivity, label:this.groupForAddActivity}
            this.$emit('resetGroupForAddActivity');
        },
        setListAndChangeOptionLanguage() {
            if(this.selectedAction) this.selectedAction = this.actionsOptionsList.find(item => item.value == this.selectedAction.value);

            return this.actionsOptionsList;
        }
    },
}
</script>

<style lang="scss">
    #card-activity-session {

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
            .container-buttons {
                display: flex;
                .btn-activity {
                    display: flex;
                    padding: 0 !important;
                    border-radius: 5px;
                    border: 1px solid transparent !important;
                    margin-left: 16px;
                    span {
                        width: 100%;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #FFFFFF;
                        letter-spacing: 0.0125em;
                        padding: 4px 14px;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }
                    &:active {
                        background: #A45F1F !important;
                    }
                }
                .btn-linked-activities {
                    background: #fff !important;
                    border: 1px solid #974900 !important;
                    span {
                        color: #974900;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #FFEDE2 !important;
                    }
                    &:active {
                        background: #FFDBC4 !important;
                    }
                }
            }
        }
    }

    @media (max-width:480px) {
        #card-activity-session {
            .container-card-header {
                flex-direction: column;
                .title {
                    align-self: flex-start;
                }
                .container-buttons {
                    flex-direction: column;
                    width: 100%;
                    margin-top: 16px;
                    .btn-activity {
                        width: 100%;
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }
</style>
