<template>
    <b-card id="card-activity-powershell">
        <div class="container-card-header">
            <span class="title">
                PowerShell
            </span>
        </div>

        <div cols="12">
            <b-row>
                <b-form-group
                    class="col-12"
                    label="Script PowerShell"
                    :invalid-feedback="$t('RequiredField')"
                >
                    <b-form-textarea
                        class="script-textarea"
                        v-model="scriptInput"
                        rows="6"
                        :placeholder="$t('TypeHere')"
                        :state="flagValidScript"
                    />
                </b-form-group>
            </b-row>
        </div>
    </b-card>
</template>

<script lang="js">

import { mapGetters, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive';
import {
    BRow,
    BFormGroup,
    BFormTextarea,
    BCard,
    VBToggle
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BCard,
        BFormTextarea
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        connectionsList:{
            type:Array,
            default:null
        },
        azureConnectionsList: {
            type:Array,
            default: null,
        },
        editingData: {
            type: Object,
        },
    },
    watch: {
        flagEditActivity(v) {
            if(v && this.flagRunPowerShellAction) this.setDataForEditing();
        },
        flagCleanFields(v) {
            if(v) this.cleanFields();
        },
        flagActivityButton(v) {
            if(v && this.flagRunPowerShellAction) this.saveData();
        },
        flagRunPowerShellAction(v) {
            if(!v) this.cleanFields();
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagActivityButton',
                'flagCleanFields',
                'flagEditActivity',
                'flagRunPowerShellAction',
            ]
        )
    },
    mounted() {
        if (this.flagEditActivity && this.flagRunPowerShellAction) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            flagValidScript: null,
            scriptInput: '',
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
                'UPDATE_FLAG_RUN_POWERSHELL_VALIDATION'
            ]
        ),
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let data = {
                    scriptPowerShell: this.scriptInput,
                }

                this.$emit('setValue', data);
            }
        },
        validateFields() {
            this.flagValidScript = this.scriptInput != '' ? null : false;

            let validate = this.flagValidScript == false ? false : true;
            this.UPDATE_FLAG_RUN_POWERSHELL_VALIDATION(validate);

            return validate;
        },
        cleanFields() {
            this.scriptInput = '';
            this.flagValidScript = null;
        },
        setDataForEditing() {
            this.scriptInput = this.editingData.activity.runPowerShell.scriptPowerShell;
        },
    },
}
</script>

<style lang="scss">
    #card-activity-powershell {
        overflow-x: visible;

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .script-textarea {
            min-height: 40px !important;
            padding: 8px 13px !important;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
        }
    }
</style>
