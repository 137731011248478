<template>
    <b-card id="card-activity-azure">
        <div class="container-card-header">
            <span class="title">
                Azure Data Factory
            </span>
        </div>

        <div cols="12">
            <b-row>
                <b-form-group
                    class="col-12 col-sm-6"
                    :label="$t('IntegrationPage.Connection')"
                >
                    <SingleSelect
                        id="input-azure-connection"
                        :placeholder="$t('Select')"
                        :state="flagValidConnection"
                        :optionSelected="selectedConnection"
                        :options="azureConnectionsList"
                        @input="(value) => {selectedConnection = value; !flagEditActivity ? selectedPipeline = null : ''}"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>

                <b-form-group
                    class="col-12 col-sm-6"
                    label="Pipeline"
                >
                    <SingleSelect
                        id="input-azure-pipeline"
                        :placeholder="$t('Select')"
                        :state="flagValidPipeline"
                        :disabled="selectedConnection == null"
                        :optionSelected="selectedPipeline"
                        :options="selectedConnection ? selectedConnection.objects : []"
                        @input="(value) => selectedPipeline = value"
                    >
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>
            </b-row>
        </div>
    </b-card>
</template>

<script lang="js">

import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import { mapGetters, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive';
import {
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    VBToggle
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BFormInput,
        BCard,
        SingleSelect
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        connectionsList:{
            type:Array,
            default:null
        },
        azureConnectionsList: {
            type:Array,
            default: null,
        },
        editingData: {
            type: Object,
        },
    },
    watch: {
        flagEditActivity(v) {
            if(v && this.flagAzureDataFactoryAction) this.setDataForEditing();
        },
        flagCleanFields(v) {
            if(v) this.cleanFields();
        },
        flagActivityButton(v) {
            if(v && this.flagAzureDataFactoryAction) this.saveData();
        },
        flagAzureDataFactoryAction(v) {
            if(!v) this.cleanFields();
        }
    },
    mounted() {
        if (this.flagEditActivity && this.flagAzureDataFactoryAction) this.setDataForEditing();
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagActivityButton',
                'flagCleanFields',
                'flagEditActivity',
                'flagAzureDataFactoryAction',
            ]
        )
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedConnection: null,
            selectedPipeline: null,
            flagValidConnection: null,
            flagValidPipeline: null,
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
                'UPDATE_FLAG_AZURE_DATA_FACTORY_VALIDATION'
            ]
        ),
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let data = {
                    connectionId: this.selectedConnection.value,
                    pipelineId: this.selectedPipeline.value
                }

                this.$emit('setValue', data);
            }
        },
        validateFields() {
            this.flagValidConnection = this.selectedConnection != null ? null : false;
            this.flagValidPipeline = this.selectedPipeline != null ? null : false;

            let validate = this.flagValidConnection == false || this.flagValidPipeline == false ? false : true;

            this.UPDATE_FLAG_AZURE_DATA_FACTORY_VALIDATION(validate);

            return validate;
        },
        cleanFields() {
            this.selectedConnection = null;
            this.selectedPipeline = null;
            this.flagValidConnection = null;
            this.flagValidPipeline = null;
        },
        setDataForEditing() {
            this.selectedConnection = this.azureConnectionsList.find(item => item.value == this.editingData.activity.azureDataFactory?.connectionId);
            this.selectedPipeline = this.selectedConnection.objects.find(item => item.value == this.editingData.activity.azureDataFactory?.pipelineId);
        },
    },
}
</script>

<style lang="scss">
    #card-activity-azure {
        overflow-x: visible;

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
        }
    }
</style>
