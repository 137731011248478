<template>
    <b-card id="card-activity-pipeline">
        <div class="container-card-header">
            <span class="title">
                Pipeline
            </span>
        </div>

        <div cols="12">
            <b-row>
                <b-form-group class="col-12" label="Pipeline" :invalid-feedback="$t('RequiredField')">
                    <SingleSelect id="input-pipeline" :placeholder="$t('Select')" :state="flagValidPipeline"
                        :optionSelected="selectedPipeline" :options="pipelinesLocalList"
                        @input="(value) => selectedPipeline = value">
                        {{ $t('RequiredField') }}
                    </SingleSelect>
                </b-form-group>
            </b-row>
        </div>
    </b-card>
</template>

<script>

import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import { mapGetters, mapMutations } from "vuex";
import {
    BRow,
    BFormGroup,
    BCard
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormGroup,
        BCard,
        SingleSelect
    },
    props: {
        pipelinesList: {
            type: Array,
            default: null
        },
        editingData: {
            type: Object,
        },
    },
    watch: {
        flagEditActivity(v) {
            if (v && this.flagRunPipelineAction) this.setDataForEditing();
        },
        flagCleanFields(v) {
            if (v) this.cleanFields();
        },
        flagActivityButton(v) {
            if (v && this.flagRunPipelineAction) this.saveData();
        },
        flagRunPipelineAction(v) {
            if (!v) this.cleanFields();
        },
        pipelinesList(v){
            this.pipelinesLocalList = this.pipelinesList.filter(f => f.value != this.$route.params.id && !f.eventId && f.executionType == 2)
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagCleanFields',
            'flagEditActivity',
            'flagRunPipelineAction',
        ]
        )
    },
    mounted() {
        if (this.pipelinesList?.length > 0) this.pipelinesLocalList = this.pipelinesList.filter(f => f.value != this.$route.params.id && !f.eventId && f.executionType == 2)
        if (this.flagEditActivity && this.flagRunPipelineAction) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedPipeline: null,
            flagValidPipeline: null,
            pipelinesLocalList: []
        }
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_RUN_PIPELINE_VALIDATION'
        ]
        ),
        saveData() {
            let validate = this.validateFields();

            if (validate) {
                let data = {
                    pipelineId: this.selectedPipeline.value,
                }

                this.$emit('setValue', data);
            }
        },
        validateFields() {
            this.flagValidPipeline = !this.selectedPipeline ? false : null;

            let validate = this.flagValidPipeline == false ? false : true;
            this.UPDATE_FLAG_RUN_PIPELINE_VALIDATION(validate);

            return validate;
        },
        cleanFields() {
            this.selectedPipeline = null;
            this.flagValidPipeline = null;
        },
        setDataForEditing() {
            this.selectedPipeline = this.pipelinesList.find(f => f.value == this.editingData.activity.runPipeline.pipelineId);
        },
    },
}
</script>

<style lang="scss">
#card-activity-pipeline {
    overflow-x: visible;

    .card-body {
        padding: 16px 16px 0px 16px;
    }

    input::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #CFC4BE;
    }

    label,
    legend {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0 !important;
        padding-bottom: 4px !important;
    }

    .invalid-feedback {
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        color: #D32F2F;
        text-align: left;
    }

    .container-card-header {
        flex-direction: row;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #4C4541;
        }
    }
}
</style>
