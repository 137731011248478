<template>
    <div id="container-scheduling-session">
        <b-card id="card-scheduling-session" v-show="flagSchedules">
            <b-col class="p-0">
                <div class="container-card-header">
                    <span class="title">
                        {{ $t('IntegrationPage.LinkedSchedules') }}
                    </span>

                    <div class="container-buttons">
                        <b-button
                            :class="['btn-scheduling btn-list', {'btn-list-active': flagSchedulingList}]"
                            @click="showInfos('SchedulingList')"
                        >
                            <span>
                                {{ $t('IntegrationPage.SchedulesList') }}
                            </span>
                        </b-button>
                        <b-button
                            class="btn-scheduling btn-new"
                            @click="showInfos('NewScheduling')"
                         >
                            <span>
                                {{ $t('IntegrationPage.NewScheduling') }}
                            </span>
                        </b-button>
                    </div>
                </div>

                <CardScheduling
                    :isLinked="true"
                    :arrayName="'linkedSchedules'"
                    :deleteScheduling="deleteItemList"
                    :showInfos="showInfos"
                    v-for="(scheduling, index) in linkedSchedules"
                    :key="`linked-scheduling-${index}`"
                    :data="scheduling"
                    :removeLinkedScheduling="removeScheduling"
                    @setEditingContext="(value) => setEditingContext(value)"
                />

                <div class="not-found-sch not-found-sch-linked" v-if="linkedSchedules.length == 0">
                    <span>
                        <SearchIcon />
                        <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                    </span>
                </div>

                <!-- lista -->
                <div v-show="flagSchedulingList">
                    <b-row>
                        <span class="line-block"></span>
                    </b-row>
                    <div class="container-card-header mb-0">
                        <span class="title title-list">
                            {{ $t('IntegrationPage.SchedulesList') }}
                        </span>
                    </div>

                    <b-form-input :placeholder="$t('SearchPlaceholderDefault')" @input="filterList($event)" v-model="searchText"/>

                    <CardScheduling
                        :isLinked="false"
                        :arrayName="'schedulesList'"
                        :deleteScheduling="deleteItemList"
                        :linkScheduling="linkScheduling"
                        :showInfos="showInfos"
                        v-for="(scheduling, index) in searchText != '' ? schedulesListFiltered : schedulesList"
                        :key="`listed-scheduling-${index}`"
                        :data="scheduling"
                        @setEditingContext="(value) => setEditingContext(value)"
                    />
                    <div class="not-found-sch" v-if="schedulesList.length == 0 || schedulesListFiltered.length == 0">
                        <span>
                            <SearchIcon />
                            <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                        </span>
                    </div>
                </div>

                <div class="scheduling-list-mobile" v-if="flagSchedulingList">
                    <div class="header-card-scheduling-list">
                        <div class="col-title">
                            <span class="title-card-filter">
                                {{ $t('IntegrationPage.SchedulesList') }}
                            </span>
                            <IconClose @click="showInfos('SchedulingList')"/>
                        </div>

                        <div class="input-search">
                            <b-form-input :placeholder="$t('SearchPlaceholderDefault')" @input="filterList($event)" v-model="searchText"/>
                        </div>
                    </div>
                    <div class="itens-card-scheduling">
                            <b-row>
                                <span class="line-block"></span>
                            </b-row>
                            <div v-show="true">
                                <button
                                    class="btn-box-sch"
                                    @click="selectScheduling(scheduling.id)"
                                    v-for="(scheduling, index) in searchText != '' ? schedulesListFiltered : schedulesList"
                                    :key="`listed-scheduling-${index}`"
                                >
                                    <CardScheduling
                                        :isLinked="false"
                                        :arrayName="'schedulesList'"
                                        :deleteScheduling="deleteItemList"
                                        :linkScheduling="linkScheduling"
                                        :showInfos="showInfos"
                                        :data="scheduling"
                                        :class="selectedSchedules.includes(scheduling.id) ? 'box-selected' : ''"
                                        @setEditingContext="(value) => setEditingContext(value)"
                                    />
                                </button>
                            </div>
                            <div
                                class="not-found-sch not-found-mobile"
                                v-if="schedulesList.length == 0 || schedulesListFiltered.length == 0"
                            >
                                <span>
                                    <SearchIcon />
                                    <h3>
                                        {{ $t('Operator.NoResultsFound') }}
                                    </h3>
                                </span>
                            </div>
                    </div>

                    <div class="footer-buttons ">
                        <button
                            class="btn btn-secondary btn-link-mob"
                            @click="linkMultipleScheduling"
                        >
                            <span>
                                {{ $t('IntegrationPage.Link') }}
                            </span>
                        </button>
                    </div>
                </div>
            </b-col>
        </b-card>
        <!-- novo agendamento -->
        <NewScheduling
            v-show="flagNewSchedulesIbox"
            :editingContext="editingContext"
            :showInfos="showInfos"
            :allSchedulesList="[...linkedSchedules, ...schedulesList]"
            :flagNewScheduling="flagNewScheduling"
            @ActiveFloatButton="$emit('ActiveFloatButton')"
            @changeFlagLastEmitNewPipeline="$emit('changeFlagLastEmitNewPipeline')"
            @disableEditing="editingContext = {editingID: null, editingFlag: false}"
            @changeStatusEdit="()=> editScheduling = false"
            @setNewSchedulingData="(value) => setNewSchedulingData(value)"
            @alterSchedulingData="(value) => alterSchedulingData(value)"
        />
    </div>
</template>

<script lang="js">
  import Ripple from 'vue-ripple-directive';
  import CloseIcon from '@/assets/images/icons/close-icon-small.svg';
  import DeleteIcon from '@/assets/images/icons/delete-icon-small.svg';
  import EditIcon from '@/assets/images/icons/edit-icon-small.svg';
  import DeleteDropdownIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditDropdownIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import NewScheduling from '@/views/pages/integration/pipelines/NewScheduling.vue';
  import CardScheduling from '@/views/pages/integration/pipelines/CardScheduling.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import SearchIcon from '@/assets/images/pages/Search.svg'
  import IconClose from '@/assets/images/pages/close-icon.svg';
  import moment from "moment";
  import { mapGetters, mapMutations } from "vuex";
  import {
    BRow,
    BFormInput,
    BCard,
    VBToggle,
    BButton,
    BDropdown,
    BDropdownItem,
    BCol
  } from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BFormInput,
        BCard,
        BButton,
        BDropdown,
        BDropdownItem,
        BCol,
        CloseIcon,
        DeleteIcon,
        EditIcon,
        DeleteDropdownIcon,
        EditDropdownIcon,
        NewScheduling,
        CardScheduling,
        ToastificationContent,
        SearchIcon,
        IconClose,
        moment
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            flagSchedulingList: false,
            flagSchedules: true,
            flagNewScheduling: false,
            editingContext: {
                editingID: null,
                editingFlag: false,
            },
            linkedSchedules: [],
            schedulesList: [],
            schedulesListFiltered: [],
            selectedSchedules: [],
            searchText: '',
            enableSetDataForEdit: false
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
                'flagFloatSaveButton',
                'flagNewSchedulesIbox',
                'flagGetPipeline'
            ]
        )
    },
    props: {
        pipeline: {
            type: Object,
            default: {}
        }
    },
    watch: {
        flagFloatSaveButton(v) {
            if(v && this.flagSchedules) {
                this.saveData();
            } else if(v && this.flagNewSchedulesIbox) {
                this.UPDATE_FLAG_SCHEDULES_BUTTON(true);
            }
        },
        flagSchedules(v) {
            if(v && this.flagFloatSaveButton) this.saveData()
        },
        pipeline(v) {
            if(v && this.flagGetPipeline) this.enableSetDataForEdit = true;
        },
        flagGetPipeline(v) {
            if(v && this.pipeline) this.enableSetDataForEdit = true;
        },
        enableSetDataForEdit(v) {
            if(v) this.setDataPipeline();
        }
    },
    mounted() {
        this.schedulesList.length + this.linkedSchedules.length == 0 ? this.showInfos('NewScheduling') : '';
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION',
            'UPDATE_FLAG_NEW_SCHEDULES_IBOX',
            'UPDATE_FLAG_SCHEDULES_BUTTON'
        ]),
        setNewSchedulingData(value) {
            this.linkedSchedules.push(value);
            this.linkedSchedules.sort((a, b) => a.event.localeCompare(b.event));
        },
        selectScheduling(id) {
            let index = this.selectedSchedules.indexOf(id)
            if(index != -1) return this.selectedSchedules.splice(index, 1)
            if(index == -1) return this.selectedSchedules.push(id)
        },
        linkMultipleScheduling() {
            if(this.selectedSchedules.length > 0) {
                for(let id of this.selectedSchedules){
                    this.linkScheduling(id);
                }

                this.showInfos('SchedulingList');
            }
        },
        setEditingContext(id) {
            this.editingContext.editingID = id;
            this.editingContext.editingFlag = true;
        },
        alterSchedulingData(value) {
            let linkedSchedules = this.linkedSchedules;
            let schedulesList = this.schedulesList;

            if(linkedSchedules.find(item => item.id == value.id) != undefined) {
                let index = this.getIndexOfList(value.id, linkedSchedules);
                this.linkedSchedules[index] = value;
            } else if (schedulesList.find(item => item.id == value.id) != undefined) {
                let index = this.getIndexOfList(value.id, schedulesList);
                this.schedulesList[index] = value;
                this.schedulesList.sort((a, b) => a.event.localeCompare(b.event));
                this.schedulesListFiltered = this.schedulesList;
            }
        },
        getIndexOfList(id, array) {
            let idList = array.map(item => item.id);
            let index = idList.indexOf(id);
            return index;
        },
        showInfos(infos){
            this.UPDATE_FLAG_NEW_SCHEDULES_IBOX(false);
            this.flagSchedules = false;
            this.selectedSchedules = [];
            if(infos == 'NewScheduling'){
                this.UPDATE_FLAG_NEW_SCHEDULES_IBOX(true)
                this.flagSchedulingList = false;
            } else if (infos == 'Schedules') {
                this.flagSchedules = true;
            } else if (infos == 'SchedulingList') {
                this.flagSchedules = true;
                this.flagSchedulingList = !this.flagSchedulingList;
            }
        },
        removeItemOfList(id, array) {
            let idList = array.map(item => item.id);
            let index = idList.indexOf(id);
            array.splice(index, 1);

            return array;
        },
        addItemSchedulingList(id){
            this.schedulesList.push(this.linkedSchedules.find(item => item.id == id));
            this.schedulesList.sort((a, b) => a.event.localeCompare(b.event));
        },
        removeScheduling(id, arrayName) {
            if(arrayName == 'schedulesList'){
                let array = this.schedulesList;
                this.schedulesList = this.removeItemOfList(id, array);
            } else if(arrayName == 'linkedSchedules') {
                this.addItemSchedulingList(id);
                let array = this.linkedSchedules;
                this.linkedSchedules = this.removeItemOfList(id, array);
                this.flagSchedulingList = true;
            }
        },
        linkScheduling(id){
            this.linkedSchedules.push(this.schedulesList.find(item => item.id == id));
            this.removeScheduling(id, 'schedulesList');
            this.linkedSchedules.sort((a, b) => a.event.localeCompare(b.event));
        },
        deleteItemList(id, arrayName) {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('IntegrationPage.DeleteAlertSchedule'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('IntegrationPage.Delete2'),
                customClass: {
                container: 'swal-scheduling',
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if(result.value) {
                    if(arrayName == 'schedulesList') {
                        let array = this.schedulesList;
                        this.schedulesList = this.removeItemOfList(id, array);
                    } else if (arrayName == 'linkedSchedules') {
                        let array = this.linkedSchedules;
                        this.linkedSchedules = this.removeItemOfList(id, array);
                    }

                    if(!id.includes('-')) {
                        this.$http.delete(`/api/${this.prefix}/event_script/pipelines/delete-schedule/${id}`);
                    }

                    this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', this.$t('IntegrationPage.MessageDeleteScheduling'), 'success');
                }
            })
        },
        filterList(text) {
            this.schedulesListFiltered =
            this.schedulesList.filter((o) =>
                Object.keys(o).some((k) =>{
                    switch(k) {
                        case 'startDate':
                            return this.formatDateHourForFilter(String(o[k])).includes(text);
                        case 'recurrenceNumber' || 'recurrencePeriod':
                            let recurrence =
                                `${o['recurrenceNumber']} ${(
                                        o['recurrencePeriod'] == 0 ? this.$t('IntegrationPage.Seconds')
                                        : o['recurrencePeriod'] == 1 ? this.$t('IntegrationPage.Minutes')
                                        : o['recurrencePeriod'] == 2 ? this.$t('IntegrationPage.Hours')
                                        : o['recurrencePeriod'] == 3 ? this.$t('IntegrationPage.Days2')
                                        : o['recurrencePeriod'] == 4 ? this.$t('IntegrationPage.Weeks')
                                        : o['recurrencePeriod'] == 5 ? this.$t('IntegrationPage.Months')
                                        : ''
                                ).slice(0, -1)}(s)`;

                            return recurrence.toLocaleLowerCase().includes(text.toLowerCase());
                        case 'days':
                            let days = `${o[k].length > 0
                                ? o[k].map((item) =>{
                                    return item === 'monday' ? this.$t('IntegrationPage.Monday').substr(0,3)
                                    : item === 'tuesday' ? this.$t('IntegrationPage.Tuesday').substr(0,3)
                                    : item === 'wednesday' ? this.$t('IntegrationPage.Wednesday').substr(0,3)
                                    : item === 'thursday' ? this.$t('IntegrationPage.Thursday').substr(0,3)
                                    : item === 'friday' ? this.$t('IntegrationPage.Friday').substr(0,3)
                                    : item === 'saturday' ? this.$t('IntegrationPage.Saturday').substr(0,3)
                                    : item === 'sunday' ? this.$t('IntegrationPage.Sunday').substr(0,3)
                                    : ''
                                }).join(', ') : '-'}`;

                            return days.toLowerCase().includes(text.toLowerCase());
                        default:
                            return String(o[k]).toLowerCase().includes(text.toLowerCase());
                    }
                })
            );
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: title,
                icon: icon,
                text: text,
                variant,
                },
            })
        },
        saveData() {
            if(!this.flagNewSchedulesIbox) this.UPDATE_FLAG_SCHEDULES_SESSION_VALIDATION(true);
            this.$emit('saveData', this.linkedSchedules)
        },
        setDataPipeline() {
            this.linkedSchedules = this.pipeline.schedules.sort((a, b) => a.event.localeCompare(b.event));
            this.schedulesList = this.pipeline.schedules_no_linked.sort((a, b) => a.event.localeCompare(b.event));
            this.schedulesListFiltered = this.pipeline.schedules_no_linked.sort((a, b) => a.event.localeCompare(b.event));

            this.linkedSchedules.length > 0 ? this.showInfos('Schedules') : this.schedulesList.length > 0 ? this.showInfos('SchedulingList') : '';
        },
        formatDateHourForFilter(date){
            let language = localStorage.getItem('language');

            if(language == 'pt'){
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else if (language == 'en') {
                return moment(date).format("MM/DD/YYYY HH:mm:ss");
            }

            return date;
        },
    },
}
</script>

<style lang="scss">
    #container-scheduling-session {
        .form-group {
            legend, label {
                color: #4C4541 !important;
            }
        }

        select {
            &:focus {
                box-shadow: none !important;
                border-color: #974900 !important;
            }
        }

        input {
            &:focus {
                box-shadow: none !important;
                border-color: #974900 !important;
            }

            &::placeholder {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #CFC4BE;
            }

            &.is-invalid {
                border: 1px solid #D32F2F!important;

                &:focus {
                    border-color: #D32F2F !important;
                }
            }
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .vs--open .vs__dropdown-toggle,
        .select2-container--default.select2-container--open .select2-selection--single
        {
            box-shadow: none !important;
            border-color: #974900 !important;
            input {
                border-color: transparent !important;
            }
        }
    }
    #card-scheduling-session {
        overflow-x: hidden;

        .not-found-sch {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 66px;
            border: 1px solid #CFC4BE;
            margin: 16px 0;
            border-radius: 5px;
            width: 100%;
            background: #FFF;
            span {
                display: flex;
                align-items: center;

                h3 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998F8A;
                    margin-bottom: 0;
                }

                svg {
                    margin-right: 16.13px;
                }
            }
        }

        .not-found-sch-linked {
            background: #FAFAFA;
        }

        .not-found-mobile {
            width: 90% !important;
            margin: 0 auto;
        }

        .card-body {
            padding: 16px 16px 0 16px;
        }
        .line-block {
            background-color: #D9D9D9;
            height: 1px;
            width: 100%;
        }

        .box-selected {
            .scheduling-item {
                background-color: #FFEDE2 !important;
            }
        }

        .btn-box-sch {
            width: 100%;
            border: none;
            padding: 0;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }

            .title-list {
                margin: 16px 0;
            }

            .container-buttons {
                display: flex;
                .btn-scheduling {
                    display: flex;
                    padding: 0 !important;
                    border-radius: 5px;
                    border: 1px solid transparent !important;
                    margin-left: 16px;
                    span {
                        width: 100%;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #FFFFFF;
                        letter-spacing: 0.0125em;
                        padding: 4px 14px;
                    }

                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }

                    &:active {
                        background: #A45F1F !important;
                    }
                }

                .btn-list {
                    background: #fff !important;
                    border: 1px solid #974900 !important;
                    span {
                        color: #974900;
                    }

                    &:hover {
                        box-shadow: none;
                        background: #FFEDE2 !important;
                    }

                    &:active {
                        background: #FFDBC4 !important;
                    }
                }

                .btn-list-active {
                    background: #FFDBC4 !important;
                }

                .btn-new {
                    background: #974900 !important;
                    span {
                        color: #fff;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }

                    &:active {
                        background: #A45F1F !important;
                    }

                }
            }
        }

        .scheduling-list-mobile{
            display: none;
            padding: 16px 0;
            position: fixed;
            z-index: 1000;
            width: 100%;
            height: 100%;
            background-color: #ffff;
            top: 0;
            left: 0;
            box-sizing: border-box;

            .header-card-scheduling-list{
                padding: 0 16px;
                .col-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title-card-filter{
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 26px;
                        color: #4C4541;

                    }
                }

                .input-search{
                    margin: 12px 0;
                    position: relative !important;

                    svg{
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        position: absolute !important;
                    }
                }
            }
            .itens-card-scheduling{
                height: calc(100vh - 178px);
                overflow-y:scroll;
                overflow-x: hidden;

                .scheduling-item {
                    transition: .1s;
                    margin-bottom: 0 !important;
                }

            }

            .footer-buttons{
                padding: 16px 16px 0 !important;
                width: 100%;
                position: absolute;
                bottom:16px;
                box-sizing: border-box;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                background-color: #ffff;

                .btn-link-mob{
                    width: 100%;
                    color: #fff !important;
                    background-color: #974900 !important;
                    border-color: transparent !important;
                    box-shadow: none;
                    span{
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }

                    &:active {
                        background: #A45F1F !important;
                    }
                }

            }
        }
    }

    @media (max-width:480px) {
        #card-scheduling-session {
            .container-card-header {
                flex-direction: column;
                .title {
                    align-self: flex-start;
                }
                .container-buttons {
                    flex-direction: column;
                    width: 100%;
                    margin-top: 16px;
                    .btn-scheduling {
                        width: 100%;
                        margin-left: 0;
                    }

                    .btn-list {
                        margin-bottom: 8px;
                    }
                }
            }

            .not-found-sch {
                height: 216px !important;
            }

            .scheduling-list-mobile {
                display: block !important;
            }
        }
    }

    .swal-scheduling {
        z-index: 1001;
        .swal2-modal {
            padding: 0 !important;
            border-radius: 6px;
            width: 710px;
            .swal2-close {
                color: #4C4541 !important;
                font-size: 2em;
            }
            .swal2-header {
                background-color: #D32F2F;
                padding: 20px 16px;
                border-radius: 6px 6px 0px 0px;
                flex-direction: row;
                justify-content: left;

                .swal2-title {
                    color: #ffff;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    margin: 0;
                }

                .swal2-close {
                    border-radius: 6px;
                    width: 34px;
                    background-color: #ffff;
                    height: 34px;
                    top: -5px;
                    position: absolute;
                    right: -5px;
                }
            }
            .swal2-content {
                padding: 20px 16px;

                .swal2-html-container {
                    font-weight: 400;
                    font-size: 14px;
                    text-align: left;
                    line-height: 20px;
                    color: #4C4541;
                    display: flex !important;
                }
            }
            .swal2-actions {
                padding: 0 16px 20px 16px;
                display: flex;
                justify-content: right;
                .swal2-confirm {
                    margin-left: 10px !important;
                    order: 2;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                    color: #ffff;
                    border-color: #974900 !important;
                    background-color: #974900 !important;
                    border-radius: 5px;

                    &:hover {
                    box-shadow: none !important;
                    }
                }
                .swal2-cancel {
                    order: 1;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                    color: #974900 !important;
                    border-radius: 5px;
                    border-color: transparent !important;

                    &:hover {
                    background-color: #ffede1 !important;
                    }
                }
            }
        }
    }

</style>
