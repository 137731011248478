<template>
    <div>
        <div class="card-collapse">
            <div class="click-area"  @click="showAndHideActivitiesOfActivityGroup('show')"></div>
            <b-col class="card-collapse-content col-12">
                <div class="box-item">
                    <div class="draggable-item">
                        <DraggableIcon />
                    </div>

                    <div class="group-actions-buttons d-sm-none">
                        <span>
                            <AddSmall @click="$emit('setGroupForAddActivity', data.groupName)"/>
                        </span>
                        <span>
                            <DeleteSmall @click="deleteActivityOrActivityGroup(data.id, 'activity-group')"/>
                        </span>
                    </div>
                </div>
                <div class="box-item">
                    <span class="box-title">
                        {{ $t('IntegrationPage.ActivityGroup') }}
                    </span>
                    <span class="box-value d-flex">
                        <div :id="`tooltip-activity-group-${cardIndex}`" class="add-ellipsis-la">
                            {{ data.groupName }}
                        </div>
                    </span>

                    <b-tooltip
                        custom-class="tooltip-new-pipeline"
                        :target="`tooltip-activity-group-${cardIndex}`"
                        triggers="hover blur"
                        positioning="top"
                        placement="top"
                        boundary-padding="0"
                    >
                        <div class="info-tooltip-new-pipeline">
                            {{ data.groupName }}
                        </div>
                    </b-tooltip>

                </div>
                <div class="box-item">
                    <span class="box-title">
                        {{ $t('IntegrationPage.IfSuccess') }}
                    </span>
                    <div class="box-select"  :id="`tooltip-se-sucesso-${cardIndex}`">
                        <SingleSelect
                            :id="`input-success-${cardIndex}`"
                            class="multiselect-linked-activity"
                            :placeholder="''"
                            :optionSelected="selectedSuccess"
                            :options="setOptions('success')"
                            :showFooter="true"
                            @input="(value) => selectedSuccess = value"
                            @open="() =>blockAndEnableTooltip(`tooltip-se-sucesso-${cardIndex}`, 'block')"
                            @close="() => blockAndEnableTooltip(`tooltip-se-sucesso-${cardIndex}`, 'enable')"
                        />
                        <div class="select-fake">
                            <span class="box-value">
                                {{ selectedSuccess != null ? '' : '-' }}
                            </span>
                        </div>
                    </div>
                    <b-tooltip
                        custom-class="tooltip-new-pipeline"
                        :target="`tooltip-se-sucesso-${cardIndex}`"
                        triggers="hover blur"
                        positioning="top"
                        placement="top"
                        boundary-padding="0"
                    >
                        <div class="info-tooltip-new-pipeline">
                            {{ selectedSuccess ? setOptions('success').find(item => item.value == selectedSuccess.value).text : '-' }}
                        </div>
                    </b-tooltip>
                </div>
                <div class="box-item">
                    <span class="box-title">
                        {{ $t('IntegrationPage.IfError') }}
                    </span>
                    <div class="box-select" :id="`tooltip-se-erro-${cardIndex}`">
                        <SingleSelect
                            :id="`input-error-${cardIndex}`"
                            class="multiselect-linked-activity"
                            :placeholder="''"
                            :optionSelected="selectedError"
                            :options="setOptions('error')"
                            :showFooter="true"
                            @input="(value) => selectedError = value"
                            @open="() => blockAndEnableTooltip(`tooltip-se-erro-${cardIndex}`, 'block')"
                            @close="() =>blockAndEnableTooltip(`tooltip-se-erro-${cardIndex}`, 'enable')"
                        />
                        <div class="select-fake">
                            <span class="box-value">
                                {{ selectedError != null ? '' : '-' }}
                            </span>
                        </div>
                    </div>

                    <b-tooltip
                        custom-class="tooltip-new-pipeline"
                        :target="`tooltip-se-erro-${cardIndex}`"
                        triggers="hover blur"
                        positioning="top"
                        placement="top"
                        boundary-padding="0"
                    >
                        <div class="info-tooltip-new-pipeline">
                            {{ selectedError ? setOptions('error').find(item => item.value == selectedError.value).text : '-' }}
                        </div>
                    </b-tooltip>
                </div>
                <div class="box-item justify-content-end align-items-center flex-row">
                    <div class="d-none d-sm-flex d-md-none box-item">
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            class="d-action-activity"
                            no-caret
                            right
                        >
                            <template v-slot:button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25"
                                />
                            </template>
                            <b-dropdown-item @click="$emit('setGroupForAddActivity', data.groupName)">
                                <span class="icon-action-acitivity">
                                    <AddDropdownIcon />
                                </span>
                                <span class="text-action-pipeline">
                                    {{ $t('IntegrationPage.NewActivity') }}
                                </span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteActivityOrActivityGroup(data.id, 'activity-group')">
                                <span class="icon-action-acitivity">
                                    <DeleteDropdownIcon />
                                </span>
                                <span class="text-action-pipeline">
                                    {{ $t('IntegrationPage.Delete2') }}
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="d-none d-md-flex group-actions-buttons">
                        <span>
                            <AddSmall @click="$emit('setGroupForAddActivity', data.groupName)" :id="`tooltip-btn-add-activity-group-${cardIndex}`"/>
                        </span>
                        <b-tooltip
                            custom-class="tooltip-new-pipeline"
                            :target="`tooltip-btn-add-activity-group-${cardIndex}`"
                            triggers="hover blur"
                            positioning="top"
                            placement="top"
                            boundary-padding="0"
                        >
                            <div class="info-tooltip-new-pipeline">
                                {{ $t('IntegrationPage.NewActivity') }}
                            </div>
                        </b-tooltip>

                        <span>
                            <DeleteSmall @click="deleteActivityOrActivityGroup(data.id, 'activity-group')" :id="`tooltip-btn-remove-group-${cardIndex}`"/>
                        </span>
                        <b-tooltip
                            custom-class="tooltip-new-pipeline"
                            :target="`tooltip-btn-remove-group-${cardIndex}`"
                            triggers="hover blur"
                            positioning="top"
                            placement="top"
                            boundary-padding="0"
                        >
                            <div class="info-tooltip-new-pipeline">
                                {{ $t('IntegrationPage.DeleteActivityGroup') }}
                            </div>
                        </b-tooltip>
                    </div>
                    <b-button
                        :class="['btn-collapse', collapseOpen && data.activities.length > 0 ? 'btn-collapse-rotate' : '']"
                        @click="showCollapse"
                    >
                        <span>
                            <ArrowCollapse />
                        </span>
                    </b-button>
                </div>
            </b-col>
            <b-collapse :id="`collapse-activity-group-${cardIndex}`">
                <b-row
                    v-for="(item, index) in data.activities"
                    :key="`activity-of-group-${index}`"
                >
                    <b-col class="col-12">
                        <div
                            class="linked-activity-item d-flex justify-content-between align-items-center"
                        >
                            <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
                                <span class="title-item">
                                    {{ $t('IntegrationPage.Activity') }}
                                </span>
                                <span class="text-item d-flex">
                                    <div :id="`tooltip-act-name-${cardIndex}-${index}`" class="add-ellipsis-act-name">
                                        {{ item.infos.activityName }}
                                    </div>
                                </span>
                                <b-tooltip
                                    custom-class="tooltip-new-pipeline"
                                    :target="`tooltip-act-name-${cardIndex}-${index}`"
                                    triggers="hover blur"
                                    positioning="top"
                                    placement="top"
                                    boundary-padding="0"
                                >
                                    <div class="info-tooltip-new-pipeline">
                                        {{ item.infos.activityName }}
                                    </div>
                                </b-tooltip>
                            </div>
                            <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
                                <span class="title-item">
                                    {{ $t('IntegrationPage.Action2') }}
                                </span>
                                <span class="text-item">
                                    {{
                                        item.infos.action == 1 ? $t('IntegrationPage.RunStoredProcedure')
                                        : item.infos.action == 2 ? $t('IntegrationPage.RunIntegration')
                                        : item.infos.action == 3 ? $t('IntegrationPage.RunAzurePipeline')
                                        : item.infos.action == 4 ? $t('IntegrationPage.RunPowerShell')
                                        : item.infos.action == 5 ? $t('IntegrationPage.RunPipeline')
                                        : item.infos.action == 6 ? $t('IntegrationPage.SendIntegrationReport')
                                        : ''
                                    }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-end item-box">
                                <div class="container-btn-linked-activity-item d-none d-sm-flex">
                                    <b-button
                                        :id="`tooltip-btn-edit-${cardIndex}-${index}`"
                                        class="btn-activity btn-edit"
                                        @click="$emit('setActivityForEditing', {groupID: data.id, activityID: item.id});UPDATE_FLAG_EDIT_ACTIVITY(true);"
                                    >
                                        <span>
                                            <EditIcon />
                                        </span>
                                    </b-button>
                                    <b-tooltip
                                        custom-class="tooltip-new-pipeline"
                                        :target="`tooltip-btn-edit-${cardIndex}-${index}`"
                                        triggers="hover blur"
                                        positioning="top"
                                        placement="top"
                                        boundary-padding="0"
                                    >
                                        <div class="info-tooltip-new-pipeline">
                                            {{ $t('IntegrationPage.EditActivity') }}
                                        </div>
                                    </b-tooltip>

                                    <b-button
                                        :id="`tooltip-btn-delete-${cardIndex}-${index}`"
                                        class="btn-activity btn-delete"
                                        @click="deleteActivityOrActivityGroup(data.id, 'activity', item.id)"
                                    >
                                        <span>
                                            <DeleteIcon />
                                        </span>
                                    </b-button>
                                    <b-tooltip
                                        custom-class="tooltip-new-pipeline"
                                        :target="`tooltip-btn-delete-${cardIndex}-${index}`"
                                        triggers="hover blur"
                                        positioning="top"
                                        placement="top"
                                        boundary-padding="0"
                                    >
                                        <div class="info-tooltip-new-pipeline">
                                            {{ $t('IntegrationPage.DeleteActivity') }}
                                        </div>
                                    </b-tooltip>

                                </div>
                                <div class="d-block d-sm-none btn-drop-activity">
                                    <span>
                                        <b-dropdown
                                            variant="link"
                                            toggle-class="text-decoration-none"
                                            no-caret
                                            class="d-action-activity"
                                        >
                                            <template v-slot:button-content>
                                                <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                                />
                                            </template>
                                            <b-dropdown-item @click="$emit('editActivity', {groupID: data.id, activityID: item.id})">
                                                <span class="icon-action-activity">
                                                    <EditDropdownIcon />
                                                </span>
                                                <span class="text-action-pipeline">
                                                    {{ $t('IntegrationPage.Edit') }}
                                                </span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deleteScheduling(data.id, 'group')">
                                                <span class="icon-action-activity">
                                                    <DeleteDropdownIcon />
                                                </span>
                                                <span class="text-action-pipeline">
                                                    {{ $t('IntegrationPage.Delete2') }}
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-collapse>
        </div>

        <div class="activity-list-mobile" v-if="flagActivitiesMobile">
            <div class="header-card-activity-list">
                <div class="col-title">
                    <span class="title-card-filter">
                        {{ data.groupName }}
                    </span>
                    <IconClose @click="showAndHideActivitiesOfActivityGroup('hidden')"/>
                </div>
            </div>
            <div class="itens-card-activity">
                    <b-row>
                        <span class="line-block"></span>
                    </b-row>
                    <div>
                        <div
                            v-for="(activity, index) in data.activities"
                            :key="`listed-activity-${index}`"
                        >
                            <b-row>
                                <b-col class="col-12">
                                    <div
                                        class="linked-activity-item d-flex flex-column"
                                    >
                                        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
                                            <span class="title-item">
                                                {{ $t('IntegrationPage.Activity') }}
                                            </span>
                                            <span class="text-item">
                                                {{ activity.infos.activityName }}
                                            </span>
                                        </div>
                                        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
                                            <span class="title-item">
                                                {{ $t('IntegrationPage.Action2') }}
                                            </span>
                                            <span class="text-item">
                                                {{
                                                    activity.infos.action == 1 ? $t('IntegrationPage.RunStoredProcedure')
                                                    : activity.infos.action == 2 ? $t('IntegrationPage.RunIntegration')
                                                    : activity.infos.action == 3 ? $t('IntegrationPage.RunAzurePipeline')
                                                    : activity.infos.action == 4 ? $t('IntegrationPage.RunPowerShell')
                                                    : activity.infos.action == 5 ? $t('IntegrationPage.RunPipeline')
                                                    : activity.infos.action == 6 ? $t('IntegrationPage.SendIntegrationReport')
                                                    : ''
                                                }}
                                            </span>
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <div class="btn-drop-linked-activity">
                                                <span>
                                                    <b-dropdown
                                                        variant="link"
                                                        toggle-class="text-decoration-none"
                                                        no-caret
                                                        class="d-action-activity"
                                                    >
                                                        <template v-slot:button-content>
                                                            <feather-icon
                                                            icon="MoreVerticalIcon"
                                                            size="16"
                                                            class="text-body align-middle mr-25"
                                                            />
                                                        </template>
                                                        <b-dropdown-item @click="editByMobile(data.id, activity.id)">
                                                            <span class="icon-action-activity">
                                                                <EditDropdownIcon />
                                                            </span>
                                                            <span class="text-action-pipeline">
                                                                {{ $t('IntegrationPage.Edit') }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item @click="deleteActivityOrActivityGroup(data.id, 'activity', activity.id)">
                                                            <span class="icon-action-activity">
                                                                <DeleteDropdownIcon />
                                                            </span>
                                                            <span class="text-action-pipeline">
                                                                {{ $t('IntegrationPage.Delete2') }}
                                                            </span>
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script lang="js">
  import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
  import Ripple from 'vue-ripple-directive';
  import DeleteIcon from '@/assets/images/icons/delete-icon-small.svg';
  import EditIcon from '@/assets/images/icons/edit-icon-small.svg';
  import DeleteDropdownIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditDropdownIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import AddDropdownIcon from '@/assets/images/icons/add-circle-icon.svg';
  import CloseDropdownIcon from '@/assets/images/icons/close-2-icon.svg';
  import FormSelect2 from '@core/components/form-select-2/FormSelect2';
  import ArrowCollapse from '@/assets/images/icons/arrow-down-large-dark.svg';
  import DraggableIcon from '@/assets/images/icons/draggable-icon.svg';
  import IconClose from '@/assets/images/pages/close-icon.svg';
  import DeleteSmall from '@/assets/images/icons/trash-small-icon.svg';
  import AddSmall from '@/assets/images/icons/add-circle-small-icon.svg';
  import { mapGetters, mapMutations } from "vuex";
  import {
    BRow,
    BTooltip,
    VBToggle,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BCol
  } from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BButton,
        BDropdown,
        BDropdownItem,
        BTooltip,
        DeleteIcon,
        EditIcon,
        DeleteDropdownIcon,
        AddDropdownIcon,
        CloseDropdownIcon,
        ArrowCollapse,
        FormSelect2,
        BCollapse,
        IconClose,
        EditDropdownIcon,
        SingleSelect,
        BCol,
        DraggableIcon,
        DeleteSmall,
        AddSmall
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            selectedSuccess: null,
            selectedError: null,
            collapseOpen: false,
            flagActivitiesMobile: false,
            ifSuccessOptions:[],
            ifErrorOptions:[],
            language: null,
        }
    },
    props: {
        cardIndex: {
            type: Number,
        },
        data: {
            type: Object,
            default: null
        },
        deleteActivityOrActivityGroup: {
            type: Function,
        },
        linkedActivityList: {
            type: Array,
            default: []
        }
    },
    watch: {
        selectedSuccess() {
            this.changeCase('success');
        },
        selectedError() {
            this.changeCase('error');
        },
        language(v) {
            if(v) {
                this.changeLanguageSuccessOption();
                this.changeLanguageErrorOption();
            }
        }
    },
    mounted() {
        this.closeTooltip();
        this.setCaseList();
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_EDIT_ACTIVITY'
        ]),
        showAndHideActivitiesOfActivityGroup(type) {
            if(type === 'show') {
                this.flagActivitiesMobile = true;
                this.$emit('disableDrag' , true);
            } else if(type == 'hidden') {
                this.flagActivitiesMobile = false;
                this.$emit('disableDrag' , false);
            }
        },
        showEditScheduling(){
            this.$emit('changeStatusEdit')
        },
        editByMobile(groupID, activityID) {
            this.$emit('setActivityForEditing', {groupID: groupID, activityID: activityID})
            this.UPDATE_FLAG_EDIT_ACTIVITY(true);
            this.showAndHideActivitiesOfActivityGroup('hidden')
        },
        closeTooltip(){
            window.addEventListener('scroll', ()=>{
                let tooltips = document.querySelectorAll('.tooltip');
                if(tooltips.length > 0){
                    for (let tooltip of tooltips) {
                        tooltip.style.display = 'none';
                    }
                }
            });
        },
        showCollapse() {
            this.collapseOpen = !this.collapseOpen;
            this.$root.$emit('bv::toggle::collapse', `collapse-activity-group-${this.cardIndex}`);
        },
        setCaseList() {
            let options = this.linkedActivityList.map(item => {return {value: item.groupName, text: item.groupName}})
            this.ifErrorOptions = options;
            this.ifSuccessOptions = options;

            this.selectedSuccess = this.setOptions('success').find(item => item.value == this.data.if_success);
            this.selectedError = this.setOptions('error').find(item => item.value == this.data.if_error);
        },
        changeCase(type) {
            this.$emit('changeCase', {groupID: this.data.id, type: type, selected: type == 'success' ? this.selectedSuccess?.value : this.selectedError?.value})
        },
        setOptions(type) {
            this.language = localStorage.getItem('language');

            if(type == 'success') return [...this.ifSuccessOptions, {value: 'exit', text: this.$t('IntegrationPage.QuitTheJob')}];
            if(type == 'error') return [...this.ifErrorOptions, {value: 'exit', text: this.$t('IntegrationPage.QuitTheJob')}];
        },
        changeLanguageSuccessOption() {
            if(this.selectedSuccess) this.selectedSuccess = this.setOptions('success').find(item => item.value == this.selectedSuccess.value);
        },
        changeLanguageErrorOption() {
            if(this.selectedError) this.selectedError = this.setOptions('error').find(item => item.value == this.selectedError.value);
        },
        blockAndEnableTooltip(id, action) {
            if(action == 'block') {
                this.$root.$emit('bv::hide::tooltip', id);
                this.$root.$emit('bv::disable::tooltip', id);
            } else {
                this.$root.$emit('bv::enable::tooltip', id);
            }
        }
    }
}
</script>

<style lang="scss">
    .card-collapse {
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
        border: 1px solid #CFC4BE;
        border-radius: 6px;
        cursor: pointer;
        margin-bottom: 16px;

        &:hover {
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);

        }

        .add-ellipsis-la {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .add-ellipsis-act-name {
          white-space: nowrap;
          max-width: 71%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .click-area {
            width: 100%;
            height: 100%;
            display: none;
            background-color: transparent;
            z-index: 0;
            position: absolute;
            top: 0;
        }

        .card-collapse-content {
            display: flex;
            padding: 16px 0;
            .btn-collapse {
                border: 0;
                height: 0;
                width: 0;
                background: transparent !important;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    box-shadow: none !important;
                }

                &.btn-collapse-rotate {
                    transform: rotate(180deg);
                }
            }
            .d-action-activity {
                button {
                    width: 32px;
                    height: 28px;
                    padding: 0;

                    svg circle {
                    color:#974900 !important;
                    }
                }

                .dropdown-item {
                    &:hover {
                        background-color: #FBEEE8;
                        svg path{
                            fill:#974900 !important;
                        }
                        span{
                            color:#974900 !important;
                        }
                    }
                }

                button:hover {
                    background-color: #FFDBC4!important;
                }

                svg{
                    margin: 0 !important;
                }

                .icon-action-acitivity {
                    svg {
                        height: 16px;
                        width: 16px;
                        path {
                        fill: #998F8A;
                        }
                    }

                    margin-right: 9px;
                }
            }

            .box-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 22.5px;

                .box-title {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: #998F8A;
                }

                .box-value {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #4C4541;
                    z-index: 3;
                }

                .activity-ellipsis {
                    white-space: nowrap;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    z-index: 3;
                }

                .box-select {
                    position: relative;
                    .form-group {
                        margin: 0;
                    }
                    .form-select2 {
                        z-index: 20;
                    }
                    .select-fake {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: absolute;
                        top: 0;
                        z-index: 0;
                    }
                }
            }

            .box-item:nth-child(1) {
                padding: 0;

                .draggable-item {
                    padding: 0 20px;
                    z-index: 3;
                }
            }
            // calc(máximo do container / quantidade de itens sem contar o primeiro - tamanho do primeiro / quantidade de itens sem contar o primeiro)
            .box-item:nth-child(2) {
                padding-left: 0;
                max-width: calc((100% / 3 - (48px / 4)) - ((100% / 6 - 48px / 4) / 3));
                width: calc((100% / 3 - 48px / 4) - ((100% / 6 - 48px / 4) / 3));
            }
            .box-item:nth-child(3) {
                max-width: calc((100% / 3 - 48px / 4) - ((100% / 6 - 48px / 4) / 3));
                width: calc((100% / 3 - 48px / 4) - ((100% / 6 - 48px / 4) / 3));
            }
            .box-item:nth-child(4) {
                max-width: calc((100% / 3 - 48px / 4) - ((100% / 6 - 48px / 4) / 3));
                width: calc((100% / 3 - 48px / 4) - ((100% / 6 - 48px / 4) / 3));
            }
            .box-item:nth-child(5) {
                width: calc(100% / 5 - 48px / 4);
                padding: 0;

                .group-actions-buttons {
                    span {
                        padding: 0 25px 0 0;

                        svg {
                            &:focus {
                                outline: none !important;
                            }
                        }
                    }

                    span:nth-child(2) {
                        padding: 0 9px 0 0;
                    }
                }
            }
        }

        .select2-container--default .select2-selection--single{
                min-height: 0;
                height: 25px;
                opacity: 0;
            }

            .select2-container--default.select2-container--open {
                box-shadow: none;
            }

    }

    .activity-item {
        padding: 15px;
        border-radius: 6px;
        margin-bottom: 16px;
        background: #FAFAFA;
        border: 1px solid #CFC4BE;
        .item-box {
            width: 15%;
            padding: 0 4px;
        }
        .item-box:nth-child(1){
            width: 55%;
        }
        .item-box:nth-child(4){
            width: 15%;
        }
        .title-item {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: #998F8A;
        }

        .text-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;
        }

        .btn-activity {
            background: none !important;
            border: none !important;
            padding: 0;
            width: 32px;
            height: 28px;
            margin-left: 8px;
            &:hover {
                box-shadow: none !important;
                background-color: #FFDBC4!important;
            }
        }

        .d-action-activity {
            button {
                width: 32px;
                height: 28px;
                padding: 0;

                svg circle {
                color:#974900 !important;
                }
            }

            .dropdown-item {
                &:hover {
                    background-color: #FBEEE8;
                    svg path{
                        fill:#974900 !important;
                    }
                    span{
                        color:#974900 !important;
                    }
                }
            }

            button:hover {
                background-color: #FFDBC4!important;
            }

            svg{
                margin: 0 !important;
            }

            .icon-action-acitivity {
                svg {
                    height: 16px;
                    width: 16px;
                    path {
                    fill: #998F8A;
                    }
                }

                margin-right: 9px;
            }
        }
    }

    .linked-activity-item {
        padding: 15px;
        border-radius: 6px;
        background: transparent !important;
        border: 1px solid #CFC4BE;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: none;

        .item-box {
            width: 40%;
            padding: 0 4px;
        }
        .item-box:nth-child(3){
            width: 20% !important;
        }
        .title-item {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: #998F8A;
        }

        .text-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;
        }

        .btn-activity {
            background: none !important;
            border: none !important;
            padding: 0;
            width: 32px;
            height: 28px;
            margin-left: 8px;
            &:hover {
                box-shadow: none !important;
                background-color: #FFDBC4!important;
            }
        }

        .d-action-activity {
            button {
                width: 32px;
                height: 28px;
                padding: 0;
                svg circle {
                color:#974900 !important;
                }
            }

            .dropdown-item {
                &:hover {
                    background-color: #FBEEE8;
                    svg path{
                        fill:#974900 !important;
                    }
                    span{
                        color:#974900 !important;
                    }
                }
            }

            button:hover {
                background-color: #FFDBC4!important;
            }

            svg{
                margin: 0 !important;
            }

            .icon-action-activity {
                svg {
                    height: 16px;
                    width: 16px;
                    path {
                    fill: #998F8A;
                    }
                }

                margin-right: 9px;
            }
        }
    }

    @media (max-width:480px) {
        .activity-item {
            flex-direction: column;
            justify-content: start !important;
            align-items: flex-start !important;
            position: relative;

            .d-link-sch {
                display: none;
            }

            .item-box {
                width: auto !important;
            }

            .btn-drop-activity {
                position: absolute;
                top: 17.5px;
                right: 20.87px;


            }
        }

        .card-collapse {
            padding: 0 16px 0;
            position: relative !important;

            .click-area {
                display: block;
                z-index: 0;
            }

            .card-collapse-content {
                flex-direction: column;
                position: initial !important;
                .btn-collapse {
                    display: none;
                }

                .multiselect-linked-activity {
                    z-index: 4;
                }

                .box-item {
                    max-width: 100% !important;
                    min-width: 100% !important;
                    padding: 0 2px !important;
                    margin-bottom: 16px;

                }

                .box-item:nth-child(1) {
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 24px;
                    .draggable-item {
                        padding: 0 15px 0 0 !important;
                    }

                    .group-actions-buttons {
                        z-index: 3;

                        padding-right: 16px !important;

                        svg {
                            &:focus {
                                outline: none !important;
                            }
                        }

                        span:nth-child(1) {
                            padding: 0 25px 0 10px;
                        }
                    }
                }

                .box-item:nth-child(4) {
                    margin: 0;
                }

                .box-item:nth-child(5){
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    @media (max-width:768px) and (min-width:480px){
        .multiselect-linked-activity {
            .multiselect__content-wrapper {
                min-width: 100%;
                width: 200% !important;
                right: 0;
            }
        }
    }

    .activity-list-mobile{
        padding: 16px 0;
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background-color: #ffff;
        top: 0;
        left: 0;
        box-sizing: border-box;

        .line-block {
            background-color: #D9D9D9;
            height: 1px;
            width: 100%;
        }

        .header-card-activity-list{
            padding: 0 16px 16px;
            .col-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title-card-filter{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color: #4C4541;
                    width: 90%;
                }

                svg path{
                    fill:  #974900;
                }
            }

            .input-search{
                margin: 12px 0;
                position: relative !important;

                svg{
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                    position: absolute !important;
                }
            }
        }
        .itens-card-activity{
            height: 100%;
            overflow-y:scroll;
            overflow-x: hidden;
            .linked-activity-item {
                position: relative;
                padding: 15px 15px 0 15px;
                .btn-drop-linked-activity {
                    position: absolute;
                    top: 17.5px;
                    right: 20.88px;
                }
            }
            .item-box {
                width: 100%;
                margin-bottom: 16px !important;

                .text-item {
                    width: 85%;
                }
            }

        }

        .footer-buttons{
            padding: 16px 16px 0 !important;
            width: 100%;
            position: absolute;
            bottom:16px;
            box-sizing: border-box;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            background-color: #ffff;

            .btn-link-mob{
                width: 100%;
                color: #fff !important;
                background-color: #974900 !important;
                border-color: transparent !important;
                box-shadow: none;
                span{
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                }
                &:hover {
                    box-shadow: none;
                    background: #9F5714 !important;
                }
                &:active {
                    background: #A45F1F !important;
                }
            }

        }
    }

    .multiselect-linked-activity {
        .multiselect__tags {
            padding-left: 0;
            border: none;
            background: transparent;
        }
        .multiselect__single {
            background: transparent;
            padding: 0;
        }

        .multiselect--active {
            .multiselect__tags {
                border: 1px solid grey;
                background: white;
                padding-left: 8px;
            }
        }
    }

</style>
