<template>
    <b-card id="card-activity-procedure">
        <!-- listagem de stored procedure -->
        <div v-if="flagListBox">
            <div class="container-card-header">
                <span class="title">
                    {{ $t('Stored Procedure') }}
                </span>

                <div class="container-buttons">
                    <b-button
                        class="btn-activity btn-linked-activities"
                        @click="showIBox('new')"
                    >
                        <span>
                            {{ $t('IntegrationPage.NewStoredProcedure') }}
                        </span>
                    </b-button>
                </div>
            </div>

            <b-col cols="12">
                <b-row>
                    <b-form-input
                        class="col-12 mb-2"
                        :placeholder="$t('SearchPlaceholderDefault')"
                        @input="filterList($event)"
                        v-model="searchText"
                    />
                </b-row>
            </b-col>

            <b-row>
                <b-col>
                    <CardProcedure
                        @changeStatusEdit="showIBox('edit')"
                        v-for="(item, index) in searchText != '' ? procedureListFiltered : procedureList"
                        :key="index"
                        :data="item"
                        :deleteScheduling="deleteItemList"
                        @setEditingData="(value) => setEditingData(value)"
                    />

                    <div
                        class="not-found-act"
                        v-if="procedureList.length == 0 || procedureListFiltered.length == 0"
                    >
                        <span>
                            <SearchIcon />
                            <h3>
                                {{ $t('Operator.NoResultsFound') }}
                            </h3>
                        </span>
                    </div>
                </b-col>
            </b-row>
        </div>


        <!-- nova stored procedure/edição -->
        <div v-if="flagEditLocalNewBox">
            <div class="container-card-header">
                <span class="title">
                    {{ !flagEditLocal ? $t('IntegrationPage.NewStoredProcedure') : $t('IntegrationPage.EditStoredProcedure') }}
                </span>

                <div class="container-buttons">
                    <b-button
                        class="btn-activity btn-linked-activities"
                        @click="showIBox('list')"
                    >
                        <span>
                            {{ $t('IntegrationPage.CreatedStoredProcedures') }}
                        </span>
                    </b-button>
                </div>
            </div>

            <b-row>
                <b-col class="col-12 col-sm-4">
                    <b-form-group
                        :label="$t('IntegrationPage.NameField')"
                        :invalid-feedback="flagNameEqual ? $t('NameAlreadyExists') : $t('RequiredField')"
                    >
                        <b-form-input
                            :placeholder="$t('TypeHere')"
                            v-model="nameProcedureInput"
                            :state="flagValidNameProcedure"
                        />
                    </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-4">
                    <b-form-group
                        :label="$t('IntegrationPage.TypeofExecution')"
                    >
                        <SingleSelect
                            id="input-execution-type"
                            :placeholder="$t('Select')"
                            :state="flagValidExecutionType"
                            :optionSelected="selectedExecutionType"
                            :options="setExecutionTypeOptions()"
                            @input="(value) => selectedExecutionType = value"
                        >
                            {{ $t('RequiredField') }}
                        </SingleSelect>
                    </b-form-group>
                </b-col>
                <b-col class="col-12 col-sm-4">
                    <b-form-group
                        :label="$t('IntegrationPage.LocalofExecution')"
                    >
                        <SingleSelect
                            id="input-execution-local"
                            :placeholder="$t('Select')"
                            :state="flagValidExecutionLocal"
                            :optionSelected="selectedExecutionLocal"
                            :options="setExecutionLocalOptions()"
                            @input="(value) => selectedExecutionLocal = value"
                        >
                            {{ $t('RequiredField') }}
                        </SingleSelect>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="container-btn-add-procedure d-flex justify-content-end">
                <b-button
                    class="btn-add-procedure"
                    @click="!flagEditLocal ? addProcedure() : editProcedure()"
                >
                    <span>
                        {{ !flagEditLocal ? $t('IntegrationPage.AddStoredProcedure') : $t('IntegrationPage.SaveChanges') }}
                    </span>
                </b-button>
            </b-row>
        </div>
    </b-card>
</template>

<script lang="js">

import CardProcedure from '@/views/pages/integration/pipelines/activity-session/CardProcedure.vue';
import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
import SearchIcon from '@/assets/images/pages/Search.svg'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import { mapGetters, mapMutations } from "vuex";
import {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCard,
    VBToggle,
    BButton
} from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BCard,
        BButton,
        CardProcedure,
        SearchIcon,
        SingleSelect,
        ToastificationContent
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        pressButton: {
            type: Boolean,
        },
        editingData: {
            type: Object,
            default: {}
        },
        originDestinySelected: {
            type: Object,
            default: {}
        }
    },
    watch: {
        pressButton(v){
            if(v) {
                this.saveData();
            }
        },
        flagCleanFields(v) {
            if(v) this.cleanList();
        },
        flagAfterMapIboxs(v) {
            if(!v)  {
                this.cleanList();
                this.cleanFields();
                this.showIBox('list');
            }
            if(v && this.flagEditActivity && this.flagProceduresIbox) this.setDataForEditing();
        },
        flagActivityButton(v) {
            if(v && this.flagProceduresIbox && this.flagIntegrationAction) this.saveData();
        },
        language(v) {
            if(v) {
                this.changeLanguageExecutionTypeOption();
                this.changeLanguageExecutionLocalOption();
            }
        }
    },
    mounted() {
        if (this.flagAfterMapIboxs && this.flagEditActivity && this.flagProceduresIbox) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            searchText: '',
            procedureList: [],
            procedureListFiltered: [],
            nameProcedureInput: '',
            selectedExecutionType: null,
            selectedExecutionLocal: null,
            flagValidNameProcedure: null,
            flagValidExecutionType: null,
            flagValidExecutionLocal: null,
            flagEditLocal: false,
            flagEditLocalNewBox: false,
            flagListBox: true,
            flagNameEqual: false,
            oldProcedureID: '',
            language: null,
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagCleanFields',
            'flagEditActivity',
            'flagAfterMapIboxs',
            'flagProceduresIbox',
            'flagIntegrationAction'
        ]),
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_PROCEDURES_VALIDATION'
        ]),
        setExecutionTypeOptions() {
            this.language = localStorage.getItem('language');

            let optionsList = [
                {value:'before' , text: this.$t('IntegrationPage.Before')},
                {value:'after' , text: this.$t('IntegrationPage.After')},
            ];

            return optionsList;
        },
        changeLanguageExecutionTypeOption() {
            if(this.selectedExecutionType) this.selectedExecutionType = this.setExecutionTypeOptions().find(item => item.value == this.selectedExecutionType.value);
        },
        setExecutionLocalOptions() {
            this.language = localStorage.getItem('language');

            let optionsList = [];
            //  API DEFAULT 1 - SQL 2 - SAP 3 - CSV 4 - REST 5
            if(
                (this.originDestinySelected.typeOrigin == 2 && this.originDestinySelected.typeDestiny == 2)
                || (this.originDestinySelected.typeOrigin == 2 && this.originDestinySelected.typeDestiny == 3)
            ) {
                optionsList = [
                    {value:'origem', text: this.$t('IntegrationPage.Source')},
                    {value:'destino', text: this.$t('IntegrationPage.Destination')}
                ];
            } else if(
                this.originDestinySelected.typeOrigin == 2 && this.originDestinySelected.typeDestiny == 4
                || this.originDestinySelected.typeOrigin == 2 && this.originDestinySelected.typeDestiny == 5
            ) {
                optionsList = [{value:'origem', text: this.$t('IntegrationPage.Source')}];
            } else {
                optionsList = [{value:'destino', text: this.$t('IntegrationPage.Destination')}];
            }

            return optionsList;
        },
        changeLanguageExecutionLocalOption() {
            if(this.selectedExecutionLocal) this.selectedExecutionLocal = this.setExecutionLocalOptions().find(item => item.value == this.selectedExecutionLocal.value);
        },
        addProcedure() {
            let validate = this.validateFields();

            if(
                validate
                && this.nameProcedureInput != ''
                && this.selectedExecutionType != null
                && this.selectedExecutionLocal != null
            ) {
                let object =  {
                    id: this.nameProcedureInput,
                    name: this.nameProcedureInput,
                    type:  this.selectedExecutionType.value,
                    local:  this.selectedExecutionLocal.value
                };

                this.procedureList.push(object);
                this.procedureListFiltered = this.procedureList

                this.procedureList.sort((a, b) => a.name.localeCompare(b.name));
                this.procedureListFiltered.sort((a, b) => a.name.localeCompare(b.name));

                this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', this.$t('IntegrationPage.AddToastStoredProcedure'), 'success');

                this.cleanFields();
                this.showIBox('list');
            }
        },
        editProcedure() {
            let validate = this.validateFields();

            if(
                validate
                && this.nameProcedureInput != ''
                && this.selectedExecutionType != null
                && this.selectedExecutionLocal != null
            ) {
                let object =  {
                    id: this.nameProcedureInput,
                    name: this.nameProcedureInput,
                    type: this.selectedExecutionType.value,
                    local: this.selectedExecutionLocal.value
                }

                let index = this.getIndex(this.oldProcedureID, this.procedureList);
                this.procedureList[index] = object;
                this.procedureListFiltered[index] = object;
                this.procedureList.sort((a, b) => a.name.localeCompare(b.name));
                this.procedureListFiltered.sort((a, b) => a.name.localeCompare(b.name));
                this.cleanFields();
                this.showIBox('list')
            }
        },
        validateFields() {
            if (
                this.nameProcedureInput != ''
                || (this.selectedExecutionType != null && this.selectedExecutionType.value != '')
                || (this.selectedExecutionLocal != null && this.selectedExecutionLocal.value != '')
            ) {

                let nameEqual;
                if(!this.flagEditLocal) {
                    nameEqual = this.procedureList.find(item => item.id == this.nameProcedureInput);
                    this.flagNameEqual = nameEqual && this.nameProcedureInput != '' ? true : false;
                } else {
                    nameEqual = this.procedureList.find(item => item.id == this.nameProcedureInput);
                    this.flagNameEqual = nameEqual && this.nameProcedureInput != '' && this.nameProcedureInput != this.oldProcedureID ? true : false;
                }

                this.flagValidNameProcedure =
                    (this.nameProcedureInput == '' && (this.selectedExecutionType != null || this.selectedExecutionLocal != null ))
                    || (this.nameProcedureInput != '' && this.flagNameEqual)
                    ? false : null;

                this.flagValidExecutionType =
                    (this.selectedExecutionType == null || this.selectedExecutionType == null) && (this.nameProcedureInput != '' || this.selectedExecutionLocal != null )
                    ? false : null;

                this.flagValidExecutionLocal =
                    (this.selectedExecutionLocal == null || this.selectedExecutionLocal == null) && (this.nameProcedureInput != '' || this.selectedExecutionType != null)
                    ? false : null;

                return this.flagValidNameProcedure == null && this.flagValidExecutionType == null && this.flagValidExecutionLocal == null ? true : false;
            }
            else{
                this.flagValidNameProcedure = null
                this.flagValidExecutionType = null
                this.flagValidExecutionLocal = null
            }

            return true;
        },
        cleanFields() {
            this.nameProcedureInput = '';
            this.selectedExecutionType = null;
            this.selectedExecutionLocal = null;
            this.flagValidNameProcedure = null;
            this.flagValidExecutionLocal = null;
            this.flagValidExecutionType = null;
        },
        filterList(text) {
            this.procedureListFiltered =
            this.procedureList?.map(item => item).filter((o) =>
                Object.keys(o).some((k) =>{
                    switch(k) {
                        case 'type':
                            let type = o[k] == 'after' ? this.$t('IntegrationPage.After') : o[k] == 'before' ? this.$t('IntegrationPage.Before') : '';
                            return type.toLowerCase().includes(text.toLowerCase());
                        case 'local':
                            let local = o[k] == 'origem' ? this.$t('IntegrationPage.Source') : o[k] == 'destino' ? this.$t('IntegrationPage.Destination') : '';
                            return local.toLowerCase().includes(text.toLowerCase());
                        case 'id':
                            return false;
                        default:
                            return String(o[k]).toLowerCase().includes(text.toLowerCase());
                    }
                })
            );
        },
        removeItemOfList(id, array) {
            let idList = array.map(item => item.id);
            let index = idList.indexOf(id);
            array.splice(index, 1);

            return array;
        },
        deleteItemList(id) {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('IntegrationPage.DeleteAlertStoredProcedure'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('IntegrationPage.Delete2'),
                customClass: {
                container: 'swal-scheduling',
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if(result.value) {
                    let array = this.procedureList;
                    this.procedureList = this.removeItemOfList(id, array);

                    this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', this.$t('IntegrationPage.DeleteToastStoredProcedure'), 'success');
                }
            })
        },
        showIBox(box) {
            this.flagEditLocalNewBox = false;
            this.flagListBox = false;
            this.flagEditLocal = false;

            if(box == 'new') {
                this.flagEditLocalNewBox = true;
            } else if(box == 'edit') {
                this.flagEditLocalNewBox = true;
                this.flagEditLocal = true;
            } else if(box == 'list') {
                this.flagListBox = true;
            }

            this.cleanFields();
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: title,
                icon: icon,
                text: text,
                variant,
                },
            })
        },
        saveData() {
            let validate = this.flagEditLocalNewBox ? this.validateFields() : true;

            this.UPDATE_FLAG_PROCEDURES_VALIDATION(validate);
            if(validate) {
                this.$emit('changeFlagLastEmitIntegration')
                this.$emit('setValue', this.procedureList);
            }
        },
        setEditingData(object) {
            this.showIBox('edit');

            this.oldProcedureID = object.name;
            this.nameProcedureInput = object.name;
            this.selectedExecutionType = this.setExecutionTypeOptions().find(item => item.value == object.type);
            this.selectedExecutionLocal = this.setExecutionLocalOptions().find(item => item.value == object.local);

        },
        getIndex(id, array) {
            let idList = array.map(item => item.id);
            return idList.indexOf(id);
        },
        cleanList() {
            this.procedureList = [];
            this.procedureListFiltered = [];
        },
        setDataForEditing() {
            this.procedureList = this.editingData.activity.procedures;
            this.procedureList.sort((a, b) => a.name.localeCompare(b.name));
            this.procedureListFiltered = this.procedureList;
        }
    },
}
</script>

<style lang="scss">
    #card-activity-procedure {

        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .invalid-feedback {
            font-weight: 300;
            font-size: 10px;
            line-height: 14px;
            color: #D32F2F;
            text-align: left;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
            .container-buttons {
                display: flex;
                .btn-activity {
                    display: flex;
                    padding: 0 !important;
                    border-radius: 5px;
                    border: 1px solid transparent !important;
                    margin-left: 16px;
                    span {
                        width: 100%;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 20px;
                        color: #FFFFFF;
                        letter-spacing: 0.0125em;
                        padding: 4px 14px;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }
                    &:active {
                        background: #A45F1F !important;
                    }
                }
                .btn-linked-activities {
                    background: #fff !important;
                    border: 1px solid #974900 !important;
                    span {
                        color: #974900;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #FFEDE2 !important;
                    }
                    &:active {
                        background: #FFDBC4 !important;
                    }
                }
                .btn-list-active {
                    background: #FFDBC4 !important;
                }
                .btn-new {
                    background: #974900 !important;
                    span {
                        color: #fff;
                    }
                    &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }
                    &:active {
                        background: #A45F1F !important;
                    }

                }
            }
        }

        .not-found-act {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 66px;
            border: 1px solid #CFC4BE;
            border-radius: 5px;
            width: 100%;
            background: #FFF;
            margin-bottom: 16px;
            span {
                display: flex;
                align-items: center;

                h3 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998F8A;
                    margin-bottom: 0;
                }

                svg {
                    margin-right: 16.13px;
                }
            }
        }

        .container-btn-add-procedure {
            margin: 0 0 16px 0;
            .btn-add-procedure {
                background-color: #974900 !important;
                border: none !important;

                &:hover {
                        box-shadow: none;
                        background: #9F5714 !important;
                    }

                &:active {
                    background: #A45F1F !important;
                }

                span {
                    color: #fff;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                }
            }
        }
    }

    @media (max-width:480px) {
        #card-activity-procedure {
            .container-card-header {
                flex-direction: column;
                .title {
                    align-self: flex-start;
                }
                .container-buttons {
                    flex-direction: column;
                    width: 100%;
                    margin-top: 16px;
                    .btn-activity {
                        width: 100%;
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }
</style>
