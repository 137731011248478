<template>
    <b-card id="card-activity-shipping">
        <div class="container-card-header">
            <span class="title">
                {{ $t('IntegrationPage.ShippingData') }}
            </span>
        </div>

        <b-row>
            <b-col cols="12">
                <VSelectChips
                    key="select-chips-pipeline-emails"
                    :label="$t('IntegrationPage.EmailTo')"
                    :labelFor="'Pipelines'"
                    class="v-select-pipelines"
                    :placeholder="($t('TypeHere'))"
                    :valueSelectMultiple="selectedEmails"
                    :options="emailsList"
                    :stateField="flagValidRecipients"
                    :taggable="true"
                    :pushTags="true"
                    :createOption="option => ({ label: option, value: option })"
                    :setOnBlur="true"
                    @updateData="(value) => selectedEmails = value"
                    v-if="updateMultiselectEmails"
                >
                    <span class='is-invalid-span' v-if="flagValidRecipients == false">{{$t('RequiredField')}}</span>
                </VSelectChips>
            </b-col>

            <b-col>
                <VSelectChips
                    key="select-chips-pipeline-pipelines"
                    :label="'Pipelines'"
                    :labelFor="'Pipelines'"
                    class="v-select-pipelines"
                    :placeholder="($t('WorkcenterList.Select'))"
                    :valueSelectMultiple="selectedPipelines"
                    :options="pipelinesList"
                    :stateField="flagValidPipelines"
                    @updateData="(value) => selectedPipelines = value"
                    v-if="updateMultiselectPipelines"
                >
                    <span class='is-invalid-span' v-if="flagValidPipelines == false">{{$t('RequiredField')}}</span>
                </VSelectChips>
            </b-col>
        </b-row>

        <b-col class="container-collapse">
            <div class="d-flex justify-content-between align-items-center container-header-collapse">
                <span class="title-collapse">
                    {{ $t('IntegrationPage.EmailServer') }}
                </span>
                <button type="button" v-b-toggle="'collapse-email-service'" class="btn-control-collapse" @click="() => showMinusIcon = !showMinusIcon">
                    <PlusIcon :style="`opacity: ${!showMinusIcon ? 1 : 0}`"/>
                    <MinusIcon :style="`opacity: ${showMinusIcon ? 1 : 0}`" />
                </button>
            </div>
            <b-collapse id="collapse-email-service" class="collapse-content">
                <b-form-group>
                    <div class="d-flex align-items-center container-checkbox">
                        <b-form-checkbox
                            switch
                            v-model="sslChecked"
                            id="ssl-email"
                        />
                        <span class="checkbox-title">
                            {{ $t('IntegrationPage.EnableSSL') }}
                        </span>
                    </div>
                </b-form-group>
                <b-row>
                    <form autocomplete="off" class="row w-100 col-12 mr-0 pr-0">
                        <b-col class="col-12">
                            <b-form-group :label="$t('IntegrationPage.Subject')" :invalid-feedback="$t('RequiredField')">
                                <b-form-input :placeholder="$t('TypeHere')" v-model="subjectInput" :state="flagValidSubject"/>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-12 col-sm-6">
                            <b-form-group :label="'SMTP'" :invalid-feedback="$t('RequiredField')">
                                <b-form-input :placeholder="$t('TypeHere')" v-model="smtpInput" :state="flagValidSmtp"/>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-12 col-sm-6">
                            <b-form-group :label="$t('IntegrationPage.Port')" :invalid-feedback="$t('RequiredField')">
                                <b-form-input :placeholder="$t('TypeHere')" v-model="portInput" :state="flagValidPort"/>
                            </b-form-group>
                        </b-col>
                        <b-col class="col-12 col-sm-6">
                            <b-form-group :label="$t('IntegrationPage.EmailFrom')" :invalid-feedback="$t('RequiredField')">
                                <b-form-input :placeholder="$t('TypeHere')" v-model="sendingEmailInput" :state="flagValidSendingEmail"/>
                            </b-form-group>
                        </b-col>

                        <b-col class="col-12 col-sm-6">
                            <b-form-group :label="$t('IntegrationPage.Password')" :invalid-feedback="$t('RequiredField')">
                                <b-input-group :class="['input-group-merge', flagValidPassword == false ? 'input-invalid-password' : '']">
                                    <b-form-input
                                        class="form-control-merge"
                                        id="account-retype-new-password"
                                        v-model="passwordInput"
                                        name="retype-password"
                                        placeholder="********"
                                        :type="passwordFieldType"
                                        :state="flagValidPassword"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                        :icon="passwordToggleIcon"
                                        class="cursor-pointer"
                                        @click="togglePassword"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <span class="invalid-feedback d-block" v-if="flagValidPassword == false">
                                    {{ $t('RequiredField') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </form>
                </b-row>
            </b-collapse>
        </b-col>
    </b-card>
</template>

<script lang="js">

import PlusIcon from '@/assets/images/icons/plus-icon-gray.svg';
import MinusIcon from '@/assets/images/icons/minus-icon-gray.svg';
import VSelectChips from '@/@core/components/v-select/vSelectChips.vue';
import { mapGetters, mapMutations } from "vuex";
import Ripple from 'vue-ripple-directive';

import {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCollapse,
    BCard,
    VBToggle,
    BButton
} from 'bootstrap-vue';

export default {
    name:"test",
    components: {
        BRow,
        BCol,
        BCollapse,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BCard,
        BButton,
        VSelectChips,
        PlusIcon,
        MinusIcon,
        BInputGroup,
        BInputGroupAppend,
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        editingData: {
            type: Object,
        },
        flagEdit: {
            type: Boolean,
        },
        pipelinesList: {
            type: Array,
            default: null
        }
    },
    watch: {
        flagEditActivity(v) {
            if(v && this.flagShippingAction) this.setDataForEditing();
        },
        flagActivityButton(v){
            if(v && this.flagShippingAction) this.saveData();
        },
        flagCleanFields(v) {
            if(v) this.cleanFields();
        },
        flagShippingAction(v) {
            if(!v) this.cleanFields();
        },
    },
    mounted() {
        if(this.flagEditActivity && this.flagShippingAction) this.setDataForEditing();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            showMinusIcon: false,
            controlRender: 0,
            sslChecked: true,
            selectedPipelines: [],
            subjectInput: '',
            smtpInput: '',
            portInput: '',
            sendingEmailInput: '',
            passwordInput: '',
            flagValidSubject: null,
            flagValidSmtp: null,
            flagValidPort: null,
            flagValidSendingEmail: null,
            flagValidPassword: null,
            flagValidPipelines: null,
            flagValidRecipients: null,
            passwordFieldType: 'password',
            selectedEmails: [],
            emailsList: [],
            updateMultiselectPipelines: true,
            updateMultiselectEmails: true,
        }
    },
    computed: {
        ...mapGetters('flags_controller_pipelines', [
            'flagActivityButton',
            'flagEditActivity',
            'flagShippingAction',
            'flagCleanFields'
        ]),
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        ...mapMutations('flags_controller_pipelines', [
            'UPDATE_FLAG_SHIPPING_VALIDATION'
        ]),
        validateFields() {
            this.flagValidPipelines = this.selectedPipelines != null && this.selectedPipelines.length > 0 ? null : false;
            this.flagValidRecipients = this.selectedEmails && this.selectedEmails.length > 0 ? null : false;
            this.flagValidSubject =
                this.subjectInput == '' && (this.smtpInput != '' || this.portInput != ''
                || this.sendingEmailInput != '' || this.passwordInput != '')
                ? false : null;

            this.flagValidSmtp =
            this.smtpInput == '' && (this.subjectInput != '' || this.portInput != ''
                || this.sendingEmailInput != '' || this.passwordInput != '')
                ? false : null;

            this.flagValidPort =
            this.portInput == '' && (this.subjectInput != '' || this.smtpInput != ''
                || this.sendingEmailInput != '' || this.passwordInput != '')
                ? false : null;

            this.flagValidSendingEmail =
            this.sendingEmailInput == '' && (this.subjectInput != '' || this.smtpInput != ''
                || this.portInput != ''  || this.passwordInput != '')
                ? false : null;

            this.flagValidPassword =
            this.passwordInput == '' && (this.subjectInput != '' || this.smtpInput != ''
                || this.portInput != ''  || this.sendingEmailInput != '')
                ? false : null;

            let validate = this.flagValidPipelines == false || this.flagValidSubject == false
            || this.flagValidSmtp == false || this.flagValidPort == false || this.flagValidSendingEmail == false
            || this.flagValidPassword == false ? false : true;

            this.UPDATE_FLAG_SHIPPING_VALIDATION(validate);
            return validate;
        },
        setPipelineOptions() {
            let optionsList = [
                {value: 1, label: this.$t('IntegrationPage.Seconds')},
                {value: 2, label: this.$t('IntegrationPage.Minutes')},
                {value: 3, label: this.$t('IntegrationPage.Hours')},
                {value: 4, label: this.$t('IntegrationPage.Days2') },
                {value: 5, label: this.$t('IntegrationPage.Weeks')},
                {value: 6, label: this.$t('IntegrationPage.Months')},
            ];

            return optionsList;
        },
        togglePassword() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        saveData() {
            let validate = this.validateFields();

            if(validate) {
                let object = {
                    to: this.selectedEmails.map(email => email.label),
                    pipelines: this.selectedPipelines.map(item => Number(item.value)),
                    email_server: {
                        ssl: this.sslChecked,
                        subject: this.subjectInput,
                        smtp: this.smtpInput,
                        port: this.portInput,
                        from: this.sendingEmailInput,
                        password: this.passwordInput
                    }
                };

                this.$emit('setValue', object)
            }
        },
        setDataForEditing() {
            this.updateMultiselectEmails = false;
            this.updateMultiselectPipelines = false;
            this.$nextTick(() => {
                this.updateMultiselectEmails = true;
                this.updateMultiselectPipelines = true;
            })
            setTimeout(() => {
                this.selectedEmails = this.editingData.activity.emailServer?.to.map((item) => { return { label: item, value: item} });
                this.emailsList = this.editingData.activity.emailServer?.to.map((item) => { return { label: item, value: item} });
                this.selectedPipelines = this.editingData.activity.emailServer?.pipelines?.map(el1 => {
                    return this.pipelinesList.find(el2 => el2.value == el1)
                })

                this.sslChecked = this.editingData.activity.emailServer?.email_server?.ssl;
                this.subjectInput = this.editingData.activity.emailServer?.email_server?.subject;
                this.smtpInput = this.editingData.activity.emailServer?.email_server.smtp;
                this.portInput = this.editingData.activity.emailServer?.email_server.port;
                this.sendingEmailInput = this.editingData.activity.emailServer?.email_server?.from;
                this.passwordInput = this.editingData.activity.emailServer?.email_server?.password;
            }, 3100)
        },
        cleanFields() {
            this.showMinusIcon = false;
            this.controlRender = 0;
            this.sslChecked = true;
            this.selectedPipelines = [];
            this.selectedEmails = [];
            this.subjectInput = '';
            this.smtpInput = '';
            this.portInput = '';
            this.sendingEmailInput = '';
            this.passwordInput = '';
            this.flagValidSubject = null;
            this.flagValidSmtp = null;
            this.flagValidPort = null;
            this.flagValidSendingEmail = null;
            this.flagValidPassword = null;
            this.flagValidPipelines = null;
            this.flagValidRecipients = null;
            this.passwordFieldType = 'password';
        }
    },
}
</script>

<style lang="scss">

    #card-activity-shipping {
        .card-body {
            padding: 16px 16px 0px 16px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #CFC4BE;
        }

        label,
        legend {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0 !important;
            padding-bottom: 4px !important;
        }

        .container-card-header {
            flex-direction: row;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4C4541;
            }
            .title-list {
                margin: 16px 0;
            }
        }

        .container-collapse {
            padding: 16px;
            background: #FAFAFA;
            border: 1px solid #CFC4BE;
            border-radius: 6px;
            margin-bottom: 16px;

            .container-header-collapse {
                .title-collapse {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #4C4541;
                }

                .btn-control-collapse {
                    border: 0;
                    padding: 7px;
                    display: flex;
                    background: transparent !important;
                    position: relative;
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: .3s;
                    }
                }
            }

            .collapse-content {
                margin-top: 24px;

                .container-checkbox {
                    .custom-control-input:checked ~ .custom-control-label::before {
                        border-color: #974900;
                        background-color: #974900;
                    }

                    .custom-switch .custom-control-label::before {
                        border-color: #ECE0DB;
                        background-color: #ECE0DB;
                    }

                    .custom-switch .custom-control-label:after {
                        top: 4.3px;
                    }

                    .checkbox-title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #4C4541;
                    }
                }

                .input-invalid-password {
                    .input-group-text {
                        border-color: #ea5455;
                    }

                    .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
                        border-color: #ea5455 !important;

                        &:focus {
                            border-color: #ea5455 !important;

                        }
                    }

                }
            }
        }
    }
</style>
